import React, { useState } from 'react';
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "../../components/ui/card";
import { Alert, AlertDescription, AlertTitle } from "../../components/ui/alert";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function PasswordResetRequest() {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleResetPassword = async (e) => {
        e.preventDefault();
        setError('');
        setMessage('');
        setIsLoading(true);

        try {
            const response = await fetch('https://us-central1-quizonator-a4513.cloudfunctions.net/api/send-password-reset-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            if (response.ok) {
                setMessage(t('passwordResetRequest.successMessage'));
            } else {
                const errorData = await response.json();
                setError(`${t('passwordResetRequest.errorOccurred')}: ${errorData.message || t('passwordResetRequest.unknownError')}`);
            }
        } catch (error) {
            console.error(t('passwordResetRequest.logError'), error);
            setError(t('passwordResetRequest.sendError'));
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-r from-gray-50 to-gray-100 px-4 sm:px-6 lg:px-8">
            <Card className="w-full max-w-md bg-white shadow-lg rounded-lg">
                <CardHeader className="text-center space-y-1">
                    <CardTitle className="text-3xl font-semibold text-gray-800">{t('passwordResetRequest.title')}</CardTitle>
                    <CardDescription className="text-gray-600">
                        {t('passwordResetRequest.description')}
                    </CardDescription>
                </CardHeader>
                <CardContent className="p-6 space-y-6">
                    <form onSubmit={handleResetPassword} className="space-y-4">
                        <div>
                            <Label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                {t('passwordResetRequest.emailLabel')}
                            </Label>
                            <Input
                                id="email"
                                type="email"
                                placeholder={t('passwordResetRequest.emailPlaceholder')}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="mt-1 w-full border rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#00D1A7]"
                                required
                            />
                        </div>
                        {error && (
                            <Alert variant="destructive" className="flex items-center space-x-2">
                                <div>
                                    <AlertTitle>{t('passwordResetRequest.errorTitle')}</AlertTitle>
                                    <AlertDescription>{error}</AlertDescription>
                                </div>
                            </Alert>
                        )}
                        {message && (
                            <Alert variant="success" className="flex items-center space-x-2">
                                <div>
                                    <AlertTitle>{t('passwordResetRequest.successTitle')}</AlertTitle>
                                    <AlertDescription>{message}</AlertDescription>
                                </div>
                            </Alert>
                        )}
                        <Button
                            type="submit"
                            className="w-full py-2 bg-[#00D1A7] text-white rounded-lg hover:bg-[#00B594] disabled:opacity-50"
                            disabled={isLoading}
                        >
                            {isLoading ? t('passwordResetRequest.loading') : t('passwordResetRequest.button')}
                        </Button>
                    </form>
                </CardContent>
            </Card>
            <p className="mt-6 text-center text-sm text-gray-600">
                {t('passwordResetRequest.noAccount')}{" "}
                <Link to="/register" className="underline text-blue-600 hover:text-blue-800">
                    {t('passwordResetRequest.registerLink')}
                </Link>
            </p>
        </div>
    );
}
