import React, { useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/card';
import BackButton from './BackButton';
import { Button } from '../ui/button';
import { Progress } from '../ui/progress';
import { Badge } from '../ui/badge';
import { ArrowLeft, Clock, Calendar, CheckCircle, XCircle } from 'lucide-react';
import { useTranslation } from 'react-i18next';

const QuizDetails = ({ quiz, onBack }) => {
    const { t } = useTranslation();
    let score = "n/a";
    let pointsEarned = null, maxPoints = null;

    if (quiz.data.Ocena) {
        const scoreMatch = quiz.data.Ocena.match(/\(([^)]+)\)/);
        if (scoreMatch) {
            score = parseFloat(scoreMatch[1]?.replace('%)', '').replace(',', '.')) || "n/a";
        }

        function extractPoints(scoreText) {
            const pattern = /(\d+(?:[.,]\d+)?)[^\d]*(?:pkt\.?|punktów?)[^\d]*(\d+(?:[.,]\d+)?)/i;
            const match = scoreText.match(pattern);

            if (match) {
                pointsEarned = parseFloat(match[1].replace(',', '.')) || null;
                maxPoints = parseFloat(match[2].replace(',', '.')) || null;
            } else {
                pointsEarned = null;
                maxPoints = null;
            }
        }
        extractPoints(quiz.data.Ocena);
    }

    useEffect(() => {
        if (window.MathJax && window.MathJax.typesetPromise) {
            window.MathJax.typesetPromise();
        }
    }, [quiz]);

    return (
        <div className="space-y-6">
            {/* Powrót */}
            <BackButton onClick={onBack} />

            {/* Nagłówek */}
            <div>
                <h2 className="text-3xl font-bold tracking-tight">{quiz.data.testName}</h2>
                <p className="text-muted-foreground">{quiz.courseName}</p>
            </div>

            {/* Wynik i szczegóły */}
            <div className="grid gap-4 md:grid-cols-2">
                <Card className='border border-gray-300'>
                    <CardHeader>
                        <CardTitle className='text-xl font-bold'>{t("quizDetails.scoreTitle")}</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <div className="flex flex-col items-center justify-center space-y-4">
                            <div className="text-5xl font-bold">{score}%</div>
                            <Progress value={score} className="w-2/3" />
                            <p className="text-sm text-muted-foreground">{quiz.data.Ocena}</p>
                        </div>
                    </CardContent>
                </Card>

                <Card className='border border-gray-300'>
                    <CardHeader>
                        <CardTitle className='text-xl font-bold'>{t("quizDetails.detailsTitle")}</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <div className="space-y-4">
                            <div className="flex items-center gap-2">
                                <Badge>{quiz.data.Stan}</Badge>
                            </div>
                            <div className="flex items-center gap-2">
                                <Clock className="h-4 w-4 text-muted-foreground" />
                                <span>{t("quizDetails.time")}: {quiz.data['Wykorzystany czas']}</span>
                            </div>
                            <div className="flex items-center gap-2">
                                <Calendar className="h-4 w-4 text-muted-foreground" />
                                <span>{t("quizDetails.started")}: {quiz.data.Rozpoczęto}</span>
                            </div>
                            <div className="flex items-center gap-2">
                                <Calendar className="h-4 w-4 text-muted-foreground" />
                                <span>{t("quizDetails.completed")}: {quiz.data.Ukończono}</span>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </div>

            <Card className='border border-gray-300'>
                <CardHeader>
                    <CardTitle>{t("quizDetails.answerSummary")}</CardTitle>
                </CardHeader>
                <CardContent>
                    <div className="space-y-2">
                        <div className="flex items-center justify-between">
                            <div className="flex items-center gap-2">
                                <CheckCircle className="h-4 w-4 text-green-500" />
                                <span>{t("quizDetails.correctAnswers")}</span>
                            </div>
                            <span className="font-bold">{pointsEarned}</span>
                        </div>
                        <div className="flex items-center justify-between">
                            <div className="flex items-center gap-2">
                                <XCircle className="h-4 w-4 text-red-500" />
                                <span>{t("quizDetails.incorrectAnswers")}</span>
                            </div>
                            <span className="font-bold">{maxPoints - pointsEarned}</span>
                        </div>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
};

export default QuizDetails;
