import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import { BrainCircuit, FileQuestion, Zap, EyeOff, Clock, ShieldCheck } from 'lucide-react';
import { Helmet } from 'react-helmet';
import { useTranslation } from "react-i18next";

export default function Features() {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>{t("features.meta.title")}</title>
                <meta
                    name="description"
                    content={t("features.meta.description")}
                />
            </Helmet>

            <section className="py-16 md:py-24 lg:py-32 bg-gray-50 min-h-screen">
                <div className="container max-w-7xl px-6 md:px-8 lg:px-10 mx-auto">
                    {/* Nagłówek sekcji */}
                    <h1 className="text-4xl md:text-5xl lg:text-6xl font-extrabold text-center text-[#1B4965] mb-6 md:mb-8">
                        {t("features.heading")}
                    </h1>
                    <p className="text-lg md:text-xl text-center text-gray-700 mb-12 md:mb-16 max-w-3xl mx-auto">
                        {t("features.subheading")}
                    </p>

                    {/* Siatka funkcji */}
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                        {[
                            {
                                icon: <BrainCircuit className="h-12 w-12 mb-4 text-[#00D1A7]" />,
                                title: t("features.items.autoAction.title"),
                                description: t("features.items.autoAction.description")
                            },
                            {
                                icon: <FileQuestion className="h-12 w-12 mb-4 text-[#2196F3]" />,
                                title: t("features.items.highQuality.title"),
                                description: t("features.items.highQuality.description")
                            },
                            {
                                icon: <Zap className="h-12 w-12 mb-4 text-[#00D1A7]" />,
                                title: t("features.items.fastAnswers.title"),
                                description: t("features.items.fastAnswers.description")
                            },
                            {
                                icon: <EyeOff className="h-12 w-12 mb-4 text-[#00D1A7]" />,
                                title: t("features.items.discreet.title"),
                                description: t("features.items.discreet.description")
                            },
                            {
                                icon: <Clock className="h-12 w-12 mb-4 text-[#2196F3]" />,
                                title: t("features.items.history.title"),
                                description: t("features.items.history.description")
                            },
                            {
                                icon: <ShieldCheck className="h-12 w-12 mb-4 text-[#00D1A7]" />,
                                title: t("features.items.security.title"),
                                description: t("features.items.security.description")
                            },
                        ].map((feature, index) => (
                            <Card
                                key={index}
                                className="bg-white rounded-xl shadow-lg hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-1 text-center p-6"
                            >
                                <CardHeader>
                                    <div className="flex justify-center">{feature.icon}</div>
                                    <CardTitle className="text-xl font-bold text-[#1B4965] mb-3">
                                        {feature.title}
                                    </CardTitle>
                                </CardHeader>
                                <CardContent>
                                    <p className="text-gray-600 text-base">{feature.description}</p>
                                </CardContent>
                            </Card>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
}
