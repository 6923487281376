// src/components/tutorial/TutorialOverlay.js

import React, { useState, useEffect } from 'react';
import { Button } from '../ui/button';
import { Progress } from "../ui/progress";
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "../ui/card";
import { ChevronRight, Download, Mail, Star, DollarSign, User, BarChart, Book, XIcon } from 'lucide-react';
import { useMemo } from 'react';
import { motion } from 'framer-motion';
import confetti from "canvas-confetti";
import defmode from '../../assets/tutorial/defaultmode.webp'
import immediatemode from '../../assets/tutorial/immediatemode.webp'
import savemode from '../../assets/tutorial/savemode.webp'
import snapshotmode from '../../assets/tutorial/snapshotmode.webp'
import activateImg from '../../assets/tutorial/activate.png'
import settingsClick from '../../assets/tutorial/settingsclick.png'
import settings from '../../assets/tutorial/settings.png'
import { useMediaQuery } from 'react-responsive';
import { useTranslation, Trans } from 'react-i18next';


const TutorialOverlay = ({ tutorialStep, setTutorialStep, onComplete, onClose, dashboardRefs, isVerified, sendVerificationEmail }) => {
    const { t } = useTranslation();
    const totalSteps = 12;
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const dashboardHighlights = useMemo(() => [
        {
            ref: dashboardRefs.payments,
            title: t('tutorialOverlay.dashboard.payments.title'),
            description: t('tutorialOverlay.dashboard.payments.description'),
        },
        {
            ref: dashboardRefs.account,
            title: t('tutorialOverlay.dashboard.account.title'),
            description: t('tutorialOverlay.dashboard.account.description'),
        },
        {
            ref: dashboardRefs.quizzes,
            title: t('tutorialOverlay.dashboard.quizzes.title'),
            description: t('tutorialOverlay.dashboard.quizzes.description'),
        },
    ], [dashboardRefs.payments, dashboardRefs.account, dashboardRefs.statistics, dashboardRefs.quizzes, t]);



    const [popupPosition, setPopupPosition] = useState({ top: "50%", left: "50%" });
    const [emailSent, setEmailSent] = useState(false); // Dodaj stan dla wizualnej obsługi wysłania emaila

    const handleResendVerification = () => {
        // Wyślij email weryfikacyjny
        sendVerificationEmail()
            .then(() => {
                setEmailSent(true); // Ustaw stan na true po udanym wysłaniu emaila
                setTimeout(() => setEmailSent(false), 5000); // Automatycznie zresetuj stan po 5 sekundach
            })
            .catch((error) => {
                console.error("Błąd podczas wysyłania emaila:", error);
            });
    };

    useEffect(() => {
        let currentHighlight = null;

        const updatePopupPosition = () => {
            if (currentHighlight) {
                const rect = currentHighlight.getBoundingClientRect();
                const newPosition = {
                    top: `${Math.min(rect.bottom + 20, window.innerHeight - 200)}px`,
                    left: `${Math.min(Math.max(rect.left + rect.width / 2 - 150, 20), window.innerWidth - 300)}px`,
                };
                setPopupPosition(newPosition);
            }
        };

        if (tutorialStep >= 9 && tutorialStep < 9 + dashboardHighlights.length) {
            const highlightIndex = tutorialStep - 9;
            currentHighlight = dashboardHighlights[highlightIndex]?.ref?.current;

            if (currentHighlight) {
                updatePopupPosition();

                window.addEventListener('scroll', updatePopupPosition);
                window.addEventListener('resize', updatePopupPosition);

                // **Dodajemy blokadę przewijania na urządzeniach mobilnych**
                if (isMobile) {
                    document.body.style.overflow = 'hidden';
                }

                // Stylizacja podświetlanego elementu
                currentHighlight.scrollIntoView({ behavior: "smooth", block: "center" });
                currentHighlight.style.zIndex = "1001";
                currentHighlight.style.position = "relative";
                currentHighlight.style.outline = "2px solid #00D1A7";
                currentHighlight.style.outlineOffset = "4px";
            }

            return () => {
                window.removeEventListener('scroll', updatePopupPosition);
                window.removeEventListener('resize', updatePopupPosition);

                // **Usuwamy blokadę przewijania**
                if (isMobile) {
                    document.body.style.overflow = '';
                }

                if (currentHighlight) {
                    currentHighlight.style.zIndex = "";
                    currentHighlight.style.position = "";
                    currentHighlight.style.outline = "";
                    currentHighlight.style.outlineOffset = "";
                }
            };
        }
    }, [tutorialStep, dashboardHighlights, isMobile]);

    const nextStep = () => {
        if (tutorialStep === totalSteps - 1) {
            confetti({
                particleCount: 100,
                spread: 70,
                origin: { y: 0.6 },
            });
            onComplete();
            window.scrollTo(0, 0); // Dodano scroll na górę ekranu po zakończeniu
        } else {
            setTutorialStep((prev) => prev + 1);
        }
    };

    const previousStep = () => {
        setTutorialStep((prev) => Math.max(prev - 1, 0));
    };

    const renderStepContent = () => {
        if (tutorialStep < 9) {
            // Kroki ogólne (przed dashboardem)
            switch (tutorialStep) {
                case 0:
                    return (
                        <div className="flex flex-col md:flex-row items-center justify-between px-6 py-6 gap-8">
                            <div className="text-center md:text-left md:w-1/2 space-y-4">
                                <h1 className="text-3xl font-extrabold text-gradient bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-green-500">
                                    {t('tutorialOverlay.step0.title')}
                                </h1>
                                {!isVerified ? (
                                    <>
                                        <p className="text-gray-700 text-lg leading-relaxed">
                                            {t('tutorialOverlay.step0.notVerified')}
                                        </p>
                                        <Button
                                            onClick={handleResendVerification}
                                            disabled={emailSent}
                                            className={`text-base px-4 py-2 ${emailSent ? 'bg-gray-400 cursor-not-allowed' : 'bg-[#00D1A7] hover:bg-[#00B594]'} text-white font-medium`}
                                        >
                                            {emailSent ? t('tutorialOverlay.step0.resendSuccess') : t('tutorialOverlay.step0.resendButton')}
                                        </Button>
                                    </>
                                ) : (
                                    <p className="text-green-700 text-lg font-medium">
                                        {t('tutorialOverlay.step0.verified')}
                                    </p>
                                )}
                            </div>
                            <div className="relative md:w-1/2 flex justify-center sm:mt-0 mt-12">
                                <Mail className={`w-32 h-32 ${isVerified ? 'text-green-500' : 'text-red-500 animate-bounce'}`} />
                            </div>
                        </div>
                    );

                case 1:
                    return (
                        <div className="flex flex-col items-center justify-between px-6 py-6 gap-6">
                            <div className="text-center space-y-4">
                                <h1 className="text-3xl font-extrabold text-gradient bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-pink-500">
                                    {t('tutorialOverlay.step1.title')}
                                </h1>
                                <p className="text-gray-700 text-lg leading-relaxed">
                                    {t('tutorialOverlay.step1.description')}
                                </p>
                            </div>
                            <div className="flex justify-center">
                                <Download className="text-purple-500 w-24 h-24" />
                            </div>
                            <div className="w-full flex justify-center">
                                <a
                                    href="https://chromewebstore.google.com/detail/quizonator/dhphdklknbffcgahfpiipdmafoncnndi"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-sm px-6 py-3 bg-[#00D1A7] text-white font-bold hover:bg-[#00B594] rounded-lg shadow-md"
                                >
                                    {t('tutorialOverlay.step1.button')}
                                </a>
                            </div>
                        </div>
                    );

                case 2:
                    return (
                        <>
                            <div className="flex flex-col md:flex-row items-start justify-between pt-4 gap-8">
                                {/* Tekst po lewej stronie */}
                                <div className="text-center md:text-left md:w-1/2 flex flex-col justify-start space-y-4">
                                    <h1 className="text-3xl font-extrabold text-gradient bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-green-500">
                                        {t('tutorialOverlay.step2.title')}
                                    </h1>
                                    <p className="text-gray-700 text-sm sm:text-lg leading-relaxed">
                                        {t('tutorialOverlay.step2.description')}{' '}
                                        <span className="text-blue-500 font-semibold">
                                            "{t('tutorialOverlay.step2.activate')}"
                                        </span>, {t('tutorialOverlay.step2.continue')}
                                    </p>
                                    <p className="text-gray-800 font-medium sm:text-xl text-sm">
                                        {t('tutorialOverlay.step2.confirmation')}
                                    </p>
                                </div>

                                {/* Obraz po prawej stronie */}
                                <div className="relative w-3/4 md:w-1/2 flex justify-center mx-auto">
                                    <img
                                        src={activateImg}
                                        alt={t('tutorialOverlay.step2.title')}
                                        className="rounded-lg shadow-lg border border-gray-200 max-w-[200px] sm:max-w-full"
                                    />
                                </div>
                            </div>
                        </>
                    );

                case 3: // Wyjaśnienie, gdzie znaleźć tryby Quizonatora
                    return (
                        <div className="flex flex-col items-center sm:px-8 sm:space-y-6">
                            {/* Tytuł */}
                            <h1 className="text-2xl sm:text-3xl sm:font-extrabold font-bold text-gradient bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-indigo-500">
                                {t('tutorialOverlay.step3.title')}
                            </h1>

                            {/* Opis */}
                            <p className="text-gray-700 text-xs sm:text-lg leading-relaxed text-center max-w-xs sm:max-w-3xl mt-4 sm:mt-0">
                                {window.innerWidth < 640
                                    ? t('tutorialOverlay.step3.mobileHint')
                                    : t('tutorialOverlay.step3.desktopHint')}
                            </p>

                            {/* Obrazy i opisy */}
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4 sm:mt-0">
                                {/* Pierwszy obraz */}
                                <div className="flex items-center space-x-4 sm:flex-col sm:space-x-0 sm:space-y-2">
                                    <img
                                        src={settingsClick}
                                        alt="Ustawienia - Tryby"
                                        className="rounded-lg shadow-lg border border-gray-200 max-w-[150px] sm:max-w-full"
                                    />
                                    <p className={`text-gray-600 text-center ${window.innerWidth < 640 ? "text-xs p-4" : "text-sm"}`}>
                                        {window.innerWidth < 640
                                            ? t('tutorialOverlay.step3.settingsClickMobile')
                                            : t('tutorialOverlay.step3.settingsClick')}
                                    </p>
                                </div>

                                {/* Drugi obraz */}
                                <div className="flex items-center space-x-4 sm:flex-col sm:space-x-0 sm:space-y-2">
                                    <img
                                        src={settings}
                                        alt="Ustawienia - Tryby dopasowane"
                                        className="rounded-lg shadow-lg border border-gray-200 max-w-[150px] sm:max-w-full"
                                    />
                                    <p className={`text-gray-600 text-center ${window.innerWidth < 640 ? "text-xs p-4" : "text-sm"}`}>
                                        {window.innerWidth < 640
                                            ? t('tutorialOverlay.step3.chooseModeMobile')
                                            : t('tutorialOverlay.step3.chooseMode')}
                                    </p>
                                </div>
                            </div>
                        </div>
                    );


                case 4:
                case 5:
                case 6:
                case 7:
                    const modes = [
                        {
                            title: t("tutorialOverlay.modes.basicMode.title"),
                            gif: defmode,
                            description: t("tutorialOverlay.modes.basicMode.description")
                        },
                        {
                            title: t("tutorialOverlay.modes.automaticMode.title"),
                            gif: immediatemode,
                            description: t("tutorialOverlay.modes.automaticMode.description")
                        },
                        {
                            title: t("tutorialOverlay.modes.discreetMode.title"),
                            gif: savemode,
                            description: t("tutorialOverlay.modes.discreetMode.description")
                        },
                        {
                            title: t("tutorialOverlay.modes.snapshotMode.title"),
                            gif: snapshotmode,
                            description: t("tutorialOverlay.modes.snapshotMode.description")
                        }
                    ];

                    const mode = modes[tutorialStep - 4]; // Upewniamy się, że indeks pasuje do kroku
                    return (
                        <div className="flex flex-col items-center justify-center">
                            <h1 className="text-2xl sm:text-3xl lg:text-3xl font-bold mb-2 sm:mb-4 text-gradient bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-green-500">
                                {mode.title}
                            </h1>
                            <img
                                src={mode.gif}
                                alt={mode.title}
                                className="mt-16 sm:mt-4 rounded-lg shadow-xl mb-6 border border-gray-200"
                            />
                            <p
                                className="text-lg text-center text-gray-700 leading-relaxed max-w-2xl"
                                dangerouslySetInnerHTML={{ __html: mode.description }}
                            ></p>
                        </div>
                    );



                case 8:
                    return (
                        <div className="flex flex-col items-center h-full text-center px-4">
                            {/* Ikona */}
                            <div className="bg-gradient-to-r from-yellow-400 via-yellow-500 to-yellow-600 p-4 rounded-full shadow-md mb-4">
                                <Star className="text-white w-16 h-16" />
                            </div>

                            {/* Tytuł */}
                            <h1 className="text-3xl font-bold mb-4 text-gradient bg-clip-text text-transparent bg-gradient-to-r from-yellow-500 to-orange-500">
                                {t("tutorialOverlay.step8.title")}
                            </h1>

                            {/* Opis */}
                            <p className="text-lg text-gray-700 mb-6 leading-relaxed max-w-2xl mt-12 sm:mt-0">
                                <Trans i18nKey="tutorialOverlay.step8.description">
                                    <strong>Dashboard</strong> to Twoje centrum dowodzenia! Zarządzaj <strong>płatnościami</strong>, <strong>quizami</strong> i <strong>ustawieniami</strong>.
                                </Trans>
                            </p>
                        </div>
                    );



                default:
                    return null;
            }
        } else {
            // Kroki dashboardu
            const dashboardStep = tutorialStep - 9;
            const highlight = dashboardHighlights[dashboardStep];

            if (!highlight) {
                return null;
            }

            return (
                <div className="flex flex-col items-center justify-center text-center">
                    {/* Nagłówek */}
                    <h2 className="sm:text-2xl text-lg font-bold mb-2 flex items-center gap-2">
                        {highlight.title}
                        {/* Ikona obok nagłówka */}
                        {dashboardStep === 0 && <DollarSign className="w-5 h-5" />}
                        {dashboardStep === 1 && <User className="w-5 h-5" />}
                        {/* {dashboardStep === 2 && <BarChart className="w-5 h-5" />} */}
                        {dashboardStep === 2 && <Book className="w-5 h-5" />}
                    </h2>

                    {/* Opis */}
                    <p className="text-gray-600 text-base mb-2 sm:mb-4">{highlight.description}</p>
                </div>

            );

        }
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4 sm:p-8">
            <motion.div
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.8 }}
                transition={{ duration: 0.3 }}
                className={`absolute bg-white sm:p-4 rounded-lg shadow-lg top-auto left-auto  sm:h-auto sm:w-auto  ${tutorialStep < 9 ? 'sm:max-w-2xl w-full h-full' : 'max-w-xs sm:max-w-xs lg:max-w-md'}`}
                style={{
                    top: tutorialStep > 8 ? popupPosition.top : null,
                    left: tutorialStep > 8 ? popupPosition.left : null,
                    // transform: tutorialStep >= 3 && tutorialStep <= 5 ? "translate(-50%, -50%)" : "none",
                }}
            >
                <div className="absolute top-0 right-0 m-4">
                    <button onClick={() => onClose()} className="text-sm font-bold py-2 px-4 rounded">
                        <XIcon />
                    </button>
                </div>
                <Card className=" sm:max-w-xl p-0 mx-0 align-middle w-full h-full sm:mx-auto flex flex-col">
                    <CardHeader className="mb-0 sm:mb-4">
                        <CardTitle className="text-sm sm:text-xl font-bold"></CardTitle>
                        {/* <CardDescription>Przejdźmy przez główne funkcje.</CardDescription> */}
                    </CardHeader>
                    <CardContent className={`text-center flex-1 ${tutorialStep < 9 ? 'mt-24 sm:mt-0' : ''}`}>
                        {renderStepContent()}
                    </CardContent>
                    <CardFooter
                        style={{ userSelect: "none" }}
                        className="mt-auto sm:mt-4 w-full"
                    >
                        <div className="flex justify-between w-full">
                            {tutorialStep > 0 && (
                                <Button onClick={previousStep} variant="outline" size="sm">
                                    {t('tutorialOverlay.footer.back')}
                                </Button>
                            )}
                            <Button
                                onClick={nextStep}
                                className="ml-auto"
                                variant="outline"
                                size="sm"
                            >
                                {tutorialStep === totalSteps - 1
                                    ? t('tutorialOverlay.footer.finish')
                                    : t('tutorialOverlay.footer.next')}
                                <ChevronRight className="ml-2 h-4 w-4" />
                            </Button>
                        </div>
                        <div className={`text-center mt-4 ${tutorialStep < 9 ? 'mb-8' : ''} sm:mb-0`}>
                            <Progress
                                value={((tutorialStep + 1) / totalSteps) * 100}
                                className="mb-2 sm:mb-4"
                            />
                            <span className="text-xs sm:text-sm">
                                {t('tutorialOverlay.footer.step', { current: tutorialStep + 1, total: totalSteps })}
                            </span>
                        </div>
                    </CardFooter>

                </Card>

            </motion.div>
        </div>
    );
};

export default TutorialOverlay;