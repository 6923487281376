import React from 'react';
import { Card, CardContent, CardHeader, CardDescription, CardTitle } from '../ui/card';
import { Button } from '../ui/button';
import { useTranslation } from 'react-i18next';
import { ArrowLeft, Edit, Trash } from 'lucide-react';

const Account = ({ userData, onBack, onOpenChangePassword, onOpenDeleteAccount }) => {
    const { t } = useTranslation();

    return (
        <div className="space-y-6">
            {/* Nagłówek z przyciskiem powrotu */}
            <div className="flex items-center justify-between">
                <Button variant="ghost" onClick={onBack} className="flex items-center gap-2">
                    <ArrowLeft className="h-4 w-4" />
                    {t("account.backButton")}
                </Button>
            </div>

            {/* Tytuł sekcji */}
            <div>
                <h2 className="text-3xl font-bold tracking-tight">{t("account.title")}</h2>
                <p className="text-muted-foreground">{t("account.subtitle")}</p>
            </div>

            {/* Informacje o użytkowniku */}
            <Card className="border border-gray-300">
                <CardHeader>
                    <CardTitle className="text-2xl font-semibold">{t("account.userData.title")}</CardTitle>
                    <CardDescription>{t("account.userData.description")}</CardDescription>
                </CardHeader>
                <CardContent>
                    <div className="flex justify-between items-center">
                        <div>
                            <p className="font-medium text-lg">{userData?.userName || t("account.userData.defaultName")}</p>
                            <p className="text-sm text-muted-foreground">{userData?.email || t("account.userData.defaultEmail")}</p>
                        </div>
                    </div>
                </CardContent>
            </Card>

            {/* Zarządzanie kontem */}
            <Card className="border border-gray-300">
                <CardHeader>
                    <CardTitle className="text-2xl font-semibold">{t("account.manageAccount.title")}</CardTitle>
                    <CardDescription>{t("account.manageAccount.description")}</CardDescription>
                </CardHeader>
                <CardContent className="space-y-4">
                    <div className="flex flex-col sm:flex-row sm:flex-wrap gap-4">
                        {/* Zmień hasło */}
                        <Button
                            variant="outline"
                            className="w-full sm:flex-1 flex items-center justify-center gap-2 text-gray-700 border-gray-300 hover:bg-gray-50"
                            onClick={onOpenChangePassword}
                        >
                            <Edit className="h-4 w-4" />
                            {t("account.manageAccount.changePassword")}
                        </Button>

                        {/* Usuń konto */}
                        <Button
                            variant="outline"
                            className="w-full sm:flex-1 flex items-center justify-center gap-2 text-red-600 bg-red-50 hover:bg-red-100 border-red-300"
                            onClick={onOpenDeleteAccount}
                        >
                            <Trash className="h-4 w-4" />
                            {t("account.manageAccount.deleteAccount")}
                        </Button>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
};

export default Account;
