import { Button } from '../components/ui/button';
import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../assets/logo.png';
import { auth } from '../firebase';
import { signOut } from 'firebase/auth';
import { Menu, X, Globe } from 'lucide-react';
import { FaInstagram, FaTiktok, FaSquareXTwitter } from "react-icons/fa6";
import { useLocation } from 'react-router-dom';
import { Home, Star, DollarSign, Info, HelpCircle, MessageSquare, BookOpen, Phone } from 'lucide-react';
import { logEvent } from '../analytics';
import { PromotionPopup } from "./PromoModal";
import { useTranslation } from 'react-i18next';

export default function Layout({ children }) {
    const { t, i18n } = useTranslation();
    const [user, setUser] = useState(null);
    const [isNavOpen, setIsNavOpen] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const languageMenuRef = useRef(null);
  

    // useEffect(() => {
    //     const popupShown = sessionStorage.getItem("promotionPopupShown");
    //     if (
    //         !user &&
    //         !popupShown &&
    //         !["/reset-password", "/verify-email", "/register", "/login", "/forgot-password", "/__/auth/action", "/dashboard"].some((path) =>
    //             location.pathname.startsWith(path)
    //         )
    //     ) {
    //         setShowPopup(true);
    //         sessionStorage.setItem("promotionPopupShown", "true");
    //         logEvent("Popup", "Show", "Promotion Popup");
    //     }
    // }, [user, location.pathname]);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((currentUser) => {
            setUser(currentUser);
        });
        return () => unsubscribe();
    }, []);

    const handleLogout = async () => {
        try {
            await signOut(auth);
            setUser(null);
            navigate('/login', { replace: true });
        } catch (error) {
            console.error('Błąd podczas wylogowywania:', error);
        }
    };

    const handleLinkClick = (path) => {
        navigate(path, { replace: true });
        setIsNavOpen(false); // Zwiń menu na urządzeniach mobilnych
    };

    useEffect(() => {
        const handleOutsideClick = (event) => {
            const navMenu = document.querySelector('nav');
            if (isNavOpen && navMenu && !navMenu.contains(event.target)) {
                setIsNavOpen(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isNavOpen]);



    // Przełącznik języka
    const [showLangMenu, setShowLangMenu] = useState(false);
    const languages = [
        { code: 'pl', label: 'PL' },
        { code: 'en', label: 'EN' },
        { code: 'ru', label: 'RU' },
        { code: 'uk', label: 'UK' },
    ];
    const currentLang = i18n.language || 'pl';

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setShowLangMenu(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (languageMenuRef.current && !languageMenuRef.current.contains(event.target)) {
                setShowLangMenu(false);
            }
        };

        // Добавляем слушатель событий только если меню открыто
        if (showLangMenu) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        // Очистка слушателя при размонтировании компонента или изменении состояния
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showLangMenu]);

    // Linki do menu nawigacji
    const navItems = [
        { name: t('navbar.home'), icon: <Home className="w-5 h-5 mr-2 lg:hidden" />, path: "/" },
        { name: t('navbar.advantages'), icon: <Star className="w-5 h-5 mr-2 lg:hidden" />, path: "/zalety" },
        { name: t('navbar.pricing'), icon: <DollarSign className="w-5 h-5 mr-2 lg:hidden" />, path: "/cennik" },
        { name: t('navbar.about'), icon: <Info className="w-5 h-5 mr-2 lg:hidden" />, path: "/o-nas" },
        { name: t('navbar.howItWorks'), icon: <HelpCircle className="w-5 h-5 mr-2 lg:hidden" />, path: "/jak-to-dziala" },
        { name: t('navbar.reviews'), icon: <MessageSquare className="w-5 h-5 mr-2 lg:hidden" />, path: "/opinie" },
        { name: t('navbar.faq'), icon: <BookOpen className="w-5 h-5 mr-2 lg:hidden" />, path: "/faq" },
        { name: t('navbar.contact'), icon: <Phone className="w-5 h-5 mr-2 lg:hidden" />, path: "/kontakt" },
    ];

    return (
        <div className="min-h-screen bg-gradient-to-b from-[#F0F8FF] to-[#E6ECF8] text-[#1B4965] font-sans">
            <PromotionPopup showPopup={showPopup} handleClose={() => setShowPopup(false)} />

            {/* Header */}
            <header className="sticky top-0 z-40 w-full border-b bg-white">
                <div className="container mx-auto flex items-center justify-between px-4 md:px-10 max-w-screen-xl h-16">
                    {/* Logo */}
                    <Link to="/" className="flex items-center space-x-2">
                        <img src={Logo} alt="Logo Quizonator" className="h-8 w-8" />
                        <span className="font-bold text-lg md:text-xl text-[#1B4965]">Quizonator</span>
                    </Link>

                    <div className="flex items-center space-x-4">
                        {/* Toggle Button for Mobile */}
                        <button
                            className="lg:hidden text-gray-600"
                            onClick={() => setIsNavOpen(!isNavOpen)}
                            aria-label="Toggle navigation"
                        >
                            {isNavOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
                        </button>
                    </div>

                    {/* Navigation Links */}
                    <nav
                        className={`${isNavOpen ? "block" : "hidden"} absolute top-16 left-0 w-full bg-white lg:static lg:flex lg:items-center lg:space-x-8 lg:w-auto shadow-md lg:shadow-none overflow-visible`}
                    >
                        <ul className="flex flex-col text-center lg:flex-row lg:space-x-6">
                            {navItems.map((item, index) => {
                                const isActive = location.pathname === item.path;
                                return (
                                    <li key={index} className="group">
                                        <Button
                                            onClick={() => handleLinkClick(item.path)}
                                            className={`block px-6 py-3 lg:p-0 text-sm font-medium transition-colors rounded-md ${isActive ? "text-[#00D1A7] font-bold" : "text-gray-700 hover:text-[#00D1A7]"
                                                }`}
                                        >
                                            <span>{item.icon}</span>
                                            {item.name}
                                        </Button>
                                    </li>
                                );
                            })}
                        </ul>

                        {/* CTA + Language Switcher */}
                        <div className="flex flex-col lg:flex-row lg:items-center lg:space-x-4 my-4 lg:my-0 space-y-4 lg:space-y-0 px-6 lg:px-0">
                            {/* Przełącznik języka */}
                            <div className="relative flex items-center justify-center">
                                <button

                                    className="text-sm px-4 py-2 rounded-lg bg-white text-gray-500 hover:text-[#00D1A7]   w-full lg:w-auto flex items-center space-x-2 justify-center"
                                    onClick={() => setShowLangMenu(!showLangMenu)}
                                    aria-label="Change language"
                                >
                                    <Globe className="w-5 h-5" />
                                    <span className="font-medium"> {t('navbar.language')}</span>
                                </button>
                                {showLangMenu && (
                                    <div
                                        ref={languageMenuRef}
                                        className="absolute z-50 top-full mt-1 w-24 bg-white border shadow-md rounded"
                                    >
                                        {languages.map(lng => (
                                            <button
                                                key={lng.code}
                                                className={`block w-full text-left px-4 py-2 text-sm hover:bg-gray-100 ${currentLang === lng.code ? 'font-bold' : ''}`}
                                                onClick={() => changeLanguage(lng.code)}
                                            >
                                                {lng.label}
                                            </button>
                                        ))}
                                    </div>
                                )}

                            </div>

                            {user ? (
                                <>
                                    <Button
                                        onClick={() => {
                                            handleLinkClick('/dashboard');
                                            logEvent('Navigation', 'Click', 'Dashboard');
                                        }}
                                        className="text-sm px-4 py-2 bg-white text-gray-500 border border-gray-500 hover:bg-gray-100 transition-colors w-full lg:w-auto"
                                    >
                                        {t('navbar.dashboard')}
                                    </Button>

                                    <Button
                                        className="text-sm px-4 py-2 bg-red-500 text-white hover:bg-red-600 transition-colors w-full lg:w-auto"
                                        onClick={handleLogout}
                                    >
                                        {t('navbar.logout')}
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <Button
                                        onClick={() => {
                                            handleLinkClick('/login');
                                            logEvent('Navigation', 'Click', 'Login');
                                        }}
                                        className="text-sm px-4 py-2 bg-white text-gray-500 border border-gray-500 hover:bg-gray-100 transition-colors w-full lg:w-auto"
                                    >
                                        {t('navbar.login')}
                                    </Button>

                                    <Button
                                        onClick={() => {
                                            handleLinkClick('/cennik');
                                            logEvent('Navigation', 'Click', 'Register');
                                        }}
                                        className="text-sm px-4 py-2 bg-[#00D1A7] text-white hover:bg-[#00B594] transition-colors w-full lg:w-auto"
                                    >
                                        {t('navbar.start')}
                                    </Button>
                                </>
                            )}
                        </div>
                    </nav>
                </div>
            </header>

            {/* Main Content */}
            <main>{children}</main>

            {/* Footer */}
            <footer className="bg-gradient-to-t from-[#F0F8FF] to-white border-t">
                <div className="container mx-auto py-8 pb-16 md:py-6 flex flex-col md:flex-row justify-between items-center px-6 md:px-10 text-sm text-gray-600 space-y-4 md:space-y-0">
                    {/* Logo i tekst */}
                    <div className="flex flex-row items-center space-x-2 md:space-y-0 md:space-x-4 text-center md:text-left">
                        <img src={Logo} alt="Logo Quizonator" className="h-6 w-6" />
                        <span>2025 Quizonator. {t('footer.allRightsReserved')}</span>
                    </div>

                    {/* Ikony mediów społecznościowych */}
                    <div className="flex flex-row items-center space-x-2 md:space-y-0 md:space-x-4">
                        <span className="font-medium ">{t('footer.followUs')}</span>
                        <a href="https://www.instagram.com/quizonator/" target="_blank" rel="noopener noreferrer" className="hover:text-[#00D1A7]">
                            <FaInstagram className="w-5 h-5" />
                        </a>
                        <a href="https://www.tiktok.com/@quizonatorai" target="_blank" rel="noopener noreferrer" className="hover:text-[#00D1A7]">
                            <FaTiktok className="w-5 h-5" />
                        </a>
                        <a href="https://x.com/quizonator" target="_blank" rel="noopener noreferrer" className="hover:text-[#00D1A7]">
                            <FaSquareXTwitter className="w-5 h-5" />
                        </a>
                    </div>

                    {/* Linki w stopce */}
                    <nav className="flex flex-wrap justify-center md:justify-start space-x-4">
                        <Link
                            to="/polityka-prywatnosci"
                            className="hover:underline"
                            onClick={() => handleLinkClick('/polityka-prywatnosci')}
                        >
                            {t('footer.privacyPolicy')}
                        </Link>
                        <Link
                            to="/warunki-uzytkowania"
                            className="hover:underline"
                            onClick={() => handleLinkClick('/warunki-uzytkowania')}
                        >
                            {t('footer.termsOfUse')}
                        </Link>
                        <Link
                            to="/polityka-cookies"
                            className="hover:underline"
                            onClick={() => handleLinkClick('/polityka-cookies')}
                        >
                            {t('footer.cookiePolicy')}
                        </Link>
                        <Link
                            to="/polityka-platnosci"
                            className="hover:underline"
                            onClick={() => handleLinkClick('/polityka-platnosci')}
                        >
                            {t('footer.paymentPolicy')}
                        </Link>
                        <Link
                            to="/kontakt"
                            className="hover:underline"
                            onClick={() => handleLinkClick('/kontakt')}
                        >
                            {t('footer.contact')}
                        </Link>

                    </nav>
                </div>
            </footer>

        </div>
    );
}
