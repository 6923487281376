import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from '../../ui/modal';
import { Button } from '../../ui/button';
import { Input } from '../../ui/input';
import { Label } from '../../ui/label';
import { useTranslation } from 'react-i18next';

const ChangePasswordModal = ({ isOpen, onClose, onChangePassword }) => {
    const { t } = useTranslation();

    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [passwordSuccess, setPasswordSuccess] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setPasswordError('');
        setPasswordSuccess(false);

        if (!currentPassword) {
            setPasswordError(t("changePassword.error.currentPassword"));
            return;
        }

        if (newPassword !== confirmNewPassword) {
            setPasswordError(t("changePassword.error.passwordMismatch"));
            return;
        }

        if (newPassword.length < 6) {
            setPasswordError(t("changePassword.error.passwordLength"));
            return;
        }

        try {
            await onChangePassword(currentPassword, newPassword);
            setPasswordSuccess(true);
            setCurrentPassword('');
            setNewPassword('');
            setConfirmNewPassword('');
        } catch (error) {
            setPasswordError(error.message || t("changePassword.error.generic"));
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalHeader>
                <h2 className="text-lg font-medium text-gray-900">{t("changePassword.title")}</h2>
            </ModalHeader>
            <ModalBody>
                {passwordSuccess ? (
                    <div className="text-center space-y-4">
                        <h3 className="text-xl font-bold text-green-600">{t("changePassword.success.title")}</h3>
                        <p className="text-gray-600">{t("changePassword.success.message")}</p>
                        <Button onClick={onClose} variant="primary">
                            {t("changePassword.success.closeButton")}
                        </Button>
                    </div>
                ) : (
                    <form onSubmit={handleSubmit} className="space-y-4">
                        <div>
                            <Label htmlFor="currentPassword">{t("changePassword.currentPassword")}</Label>
                            <Input
                                id="currentPassword"
                                type="password"
                                placeholder={t("changePassword.currentPasswordPlaceholder")}
                                value={currentPassword}
                                onChange={(e) => setCurrentPassword(e.target.value)}
                                required
                            />
                        </div>
                        <div>
                            <Label htmlFor="newPassword">{t("changePassword.newPassword")}</Label>
                            <Input
                                id="newPassword"
                                type="password"
                                placeholder={t("changePassword.newPasswordPlaceholder")}
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                required
                            />
                        </div>
                        <div>
                            <Label htmlFor="confirmNewPassword">{t("changePassword.confirmNewPassword")}</Label>
                            <Input
                                id="confirmNewPassword"
                                type="password"
                                placeholder={t("changePassword.confirmNewPasswordPlaceholder")}
                                value={confirmNewPassword}
                                onChange={(e) => setConfirmNewPassword(e.target.value)}
                                required
                            />
                        </div>
                        {passwordError && <div className="text-red-500 text-sm">{passwordError}</div>}
                        <div className="flex justify-between space-x-4 mt-6">
                            <Button 
                                variant="outline" 
                                onClick={onClose}
                                className="w-1/2 text-gray-700 hover:bg-gray-200 border-2 border-gray-300 font-medium"
                            >
                                {t("changePassword.cancelButton")}
                            </Button>
                            <Button 
                                type="submit" 
                                variant="primary"
                                className="w-1/2 bg-[#00D1A7] text-white hover:bg-[#00B594]"
                            >
                                {t("changePassword.submitButton")}
                            </Button>
                        </div>
                    </form>
                )}
            </ModalBody>
        </Modal>
    );
};

export default ChangePasswordModal;
