
import React from 'react';
import { useTranslation } from "react-i18next";

const PaymentPolicy = () => {
    const { t } = useTranslation();

    const sections = [
        {
            title: t('paymentPolicy.section1.title'),
            content: t('paymentPolicy.section1.content'),
        },
        {
            title: t('paymentPolicy.section2.title'),
            content: t('paymentPolicy.section2.content'),
        },
        {
            title: t('paymentPolicy.section3.title'),
            content: t('paymentPolicy.section3.content'),
        },
        {
            title: t('paymentPolicy.section4.title'),
            content: t('paymentPolicy.section4.content'),
        },
        {
            title: t('paymentPolicy.section5.title'),
            content: t('paymentPolicy.section5.content'),
        },
        {
            title: t('paymentPolicy.section6.title'),
            content: t('paymentPolicy.section6.content'),
        },
      
    ];

    return (
        <div className="min-h-screen p-8 bg-white text-gray-800">
            <div className="container mx-auto text-left rounded-lg p-6">
                <h1 className="text-4xl font-bold mb-6">{t('paymentPolicy.mainTitle')}</h1>
                <p className="mb-6 text-lg">{t('paymentPolicy.lastUpdate')}</p>

                {sections.map((section, index) => (
                    <section key={index} className="mb-8">
                        <h2 className="text-2xl font-semibold mb-4">{section.title}</h2>
                        <p className="whitespace-pre-line">{section.content}</p>
                    </section>
                ))}
            </div>
        </div>
    );
};

export default PaymentPolicy;