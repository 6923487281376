import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { confirmPasswordReset, verifyPasswordResetCode } from 'firebase/auth';
import { auth } from '../../firebase';
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "../../components/ui/card";
import { Alert, AlertDescription, AlertTitle } from "../../components/ui/alert";
import { useTranslation } from 'react-i18next';

export default function ResetPassword() {
    const { t } = useTranslation();
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isValidCode, setIsValidCode] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const getQueryParams = () => new URLSearchParams(location.search);

    useEffect(() => {
        const params = getQueryParams();
        const oobCode = params.get('oobCode');

        if (!oobCode) {
            setError(t('resetPassword.invalidLink'));
            return;
        }

        verifyPasswordResetCode(auth, oobCode)
            .then(() => setIsValidCode(true))
            .catch(() => setError(t('resetPassword.expiredLink')));
    }, [location.search, t]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        const params = getQueryParams();
        const oobCode = params.get('oobCode');

        if (!oobCode) {
            setError(t('resetPassword.invalidLink'));
            return;
        }

        if (newPassword !== confirmPassword) {
            setError(t('resetPassword.passwordMismatch'));
            return;
        }

        setIsLoading(true);

        try {
            await confirmPasswordReset(auth, oobCode, newPassword);
            setIsSuccess(true);
        } catch {
            setError(t('resetPassword.resetError'));
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-r from-gray-50 to-gray-100 px-4 sm:px-6 lg:px-8">
            <Card className="w-full max-w-md bg-white shadow-lg rounded-lg">
                <CardHeader className="text-center space-y-1">
                    <CardTitle className="text-3xl font-semibold text-gray-800">{t('resetPassword.title')}</CardTitle>
                    <CardDescription className="text-gray-600">{t('resetPassword.description')}</CardDescription>
                </CardHeader>
                <CardContent className="p-6 space-y-6">
                    {!isSuccess ? (
                        isValidCode ? (
                            <form onSubmit={handleSubmit} className="space-y-4">
                                <div>
                                    <Label htmlFor="newPassword" className="block text-sm font-medium text-gray-700">
                                        {t('resetPassword.newPasswordLabel')}
                                    </Label>
                                    <Input
                                        id="newPassword"
                                        type="password"
                                        placeholder={t('resetPassword.newPasswordPlaceholder')}
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        className="mt-1 w-full border rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#00D1A7]"
                                        required
                                    />
                                </div>
                                <div>
                                    <Label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
                                        {t('resetPassword.confirmPasswordLabel')}
                                    </Label>
                                    <Input
                                        id="confirmPassword"
                                        type="password"
                                        placeholder={t('resetPassword.confirmPasswordPlaceholder')}
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        className="mt-1 w-full border rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#00D1A7]"
                                        required
                                    />
                                </div>
                                {error && (
                                    <Alert variant="destructive" className="flex items-center space-x-2">
                                        <div>
                                            <AlertTitle>{t('resetPassword.errorTitle')}</AlertTitle>
                                            <AlertDescription>{error}</AlertDescription>
                                        </div>
                                    </Alert>
                                )}
                                <Button
                                    type="submit"
                                    className="w-full py-2 bg-[#00D1A7] text-white rounded-lg hover:bg-[#00B594] disabled:opacity-50"
                                    disabled={isLoading}
                                >
                                    {isLoading ? t('resetPassword.loading') : t('resetPassword.submitButton')}
                                </Button>
                            </form>
                        ) : (
                            <div className="space-y-4">
                                {error && (
                                    <Alert variant="destructive" className="flex items-center space-x-2">
                                        <div>
                                            <AlertTitle>{t('resetPassword.errorTitle')}</AlertTitle>
                                            <AlertDescription>{error}</AlertDescription>
                                        </div>
                                    </Alert>
                                )}
                                <Button
                                    onClick={() => navigate('/login')}
                                    className="w-full py-2 bg-[#00D1A7] text-white rounded-lg hover:bg-[#00B594]"
                                >
                                    {t('resetPassword.backToLogin')}
                                </Button>
                            </div>
                        )
                    ) : (
                        <Alert variant="success" className="flex items-center space-x-2">
                            <div>
                                <AlertTitle>{t('resetPassword.successTitle')}</AlertTitle>
                                <AlertDescription>
                                    {t('resetPassword.successMessage')}
                                </AlertDescription>
                            </div>
                        </Alert>
                    )}
                </CardContent>
                {isSuccess && (
                    <CardFooter className="text-center text-sm text-gray-600">
                        <Button onClick={() => navigate('/login')} className="text-blue-600 hover:text-blue-800">
                            {t('resetPassword.loginButton')}
                        </Button>
                    </CardFooter>
                )}
            </Card>
        </div>
    );
}
