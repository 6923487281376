// src/pages/VerifyEmail.jsx

import { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { auth } from "../firebase";
import { applyActionCode } from "firebase/auth";
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "../components/ui/card";
import { Button } from "../components/ui/button";
import { Alert, AlertDescription, AlertTitle } from "../components/ui/alert";
import axios from 'axios';
import { Modal, ModalContent, ModalHeader, ModalFooter, ModalTitle, ModalBody } from "../components/ui/modal"; // Zakładając, że masz komponent Modal
import { checkActionCode } from "firebase/auth";
import { useTranslation } from "react-i18next";

export default function VerifyEmailPage() {
    const { t } = useTranslation();
    const [isVerifying, setIsVerifying] = useState(true);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const isProcessed = useRef(false); // Ref to prevent multiple calls
    const [countdown, setCountdown] = useState(5);

    useEffect(() => {
        const verifyEmail = async () => {
            const params = new URLSearchParams(location.search);
            const oobCode = params.get('oobCode');

            if (!oobCode) {
                setError(t("verifyEmail.errors.unauthorized"));
                setIsVerifying(false);
                return;
            }

            try {
                // Get user's email using the action code
                const info = await checkActionCode(auth, oobCode);
                const email = info.data.email;

                // Apply the action code to verify the email
                await applyActionCode(auth, oobCode);

                // Activate the user's account on the backend
                await axios.post('https://us-central1-quizonator-a4513.cloudfunctions.net/api/activate-account', { email });

                setSuccess(true);

                // Start countdown and navigate to login
                const timer = setInterval(() => {
                    setCountdown(prev => {
                        if (prev <= 1) {
                            clearInterval(timer);
                            navigate('/login');
                            return 0;
                        }
                        return prev - 1;
                    });
                }, 1000);
            } catch (err) {
                setError(t("verifyEmail.errors.general"));
            } finally {
                setIsVerifying(false);
            }
        };

        verifyEmail();
    }, [location.search, navigate]);



    return (

        <div className="min-h-screen flex items-center justify-center bg-gray-50 px-4">
            <Card className="w-full max-w-md bg-white shadow-lg rounded-lg">
                <CardHeader className="text-center">
                    <CardTitle>{t("verifyEmail.title")}</CardTitle>
                </CardHeader>
                <CardContent>
                    {isVerifying ? (
                        <p>{t("verifyEmail.status.verifying")}</p>
                    ) : error ? (
                        <Alert variant="destructive">
                            <AlertTitle>{t("verifyEmail.errors.title")}</AlertTitle>
                            <AlertDescription>{error}</AlertDescription>
                        </Alert>
                    ) : success ? (
                        <Alert variant="success">
                            <AlertTitle>{t("verifyEmail.success.title")}</AlertTitle>
                            <AlertDescription>
                                {t("verifyEmail.success.description", { countdown })}
                            </AlertDescription>
                        </Alert>
                    ) : null}
                </CardContent>
                <CardFooter>
                    {error && (
                        <Button onClick={() => navigate('/login')}>
                            {t("verifyEmail.buttons.backToLogin")}
                        </Button>
                    )}
                </CardFooter>
            </Card>
        </div>
    );
}
