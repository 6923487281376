import React from 'react';
import { Card, CardContent, CardHeader, CardDescription, CardTitle } from '../ui/card';
import { Button } from '../ui/button';
import BackButton from './BackButton';
import { ScrollArea } from "../ui/scroll-area";
import { useTranslation } from 'react-i18next';
import { ArrowLeft } from 'lucide-react';

const Quizzes = ({ quizSummaries, onBack, onSelectQuiz }) => {
    const { t } = useTranslation();

    return (
        <div className="space-y-6">
            <BackButton onClick={onBack} />

            <div>
                <h2 className="text-3xl font-bold tracking-tight">{t("quizzes.title")}</h2>
                <p className="text-muted-foreground">{t("quizzes.subtitle")}</p>
            </div>

            <Card className='border !p-3 sm:p-6 border-gray-300'>
                <CardHeader>
                    <CardTitle className='text-2xl font-bold'>{t("quizzes.cardTitle")}</CardTitle>
                    <CardDescription>{t("quizzes.cardDescription")}</CardDescription>
                </CardHeader>
                <CardContent>
                    <ScrollArea className="h-[400px] sm:h-[600px] lg:h-[800px] pr-1 sm:pr-4">
                        {quizSummaries.length > 0 ? (
                            <div className="space-y-4">
                                {quizSummaries.map((quiz) => (
                                    <div
                                        key={quiz.id}
                                        className="flex items-center justify-between p-2 sm:p-4 bg-muted rounded-lg border border-gray-300"
                                    >
                                        <div className="flex-1 min-w-0">
                                            <h3 className="font-medium truncate">{quiz.data.testName}</h3>
                                            <p className="text-sm text-muted-foreground truncate">
                                                {t("quizzes.course")}: {quiz.courseName}
                                            </p>
                                            <p className="text-sm text-muted-foreground truncate">
                                                {t("quizzes.grade")}: {quiz.data.Ocena}
                                            </p>
                                        </div>

                                        <Button
                                            variant="outline"
                                            onClick={() => onSelectQuiz(quiz)}
                                        >
                                            {t("quizzes.detailsButton")}
                                        </Button>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p className="text-center py-4 text-muted-foreground">
                                {t("quizzes.noQuizzes")}
                            </p>
                        )}
                    </ScrollArea>
                </CardContent>
            </Card>
        </div>
    );
};

export default Quizzes;
