import React, { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

function CheckoutSuccess() {
    const [searchParams] = useSearchParams();
    const sessionId = searchParams.get("session_id");
    // W URL Stripe przekazuje "?session_id=cs_test_XXXX"

    const navigate = useNavigate();

    useEffect(() => {
        // Tutaj możesz (opcjonalnie) zrobić zapytanie do backendu,
        // by potwierdzić, że sessionId faktycznie jest opłacona.
        // Ale najczęściej czekasz na webhook Stripe, a tę stronę
        // traktujesz raczej czysto informacyjnie.
    }, [sessionId]);

    const handleGoToDashboard = () => {
        navigate("/dashboard");
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-white">
            <h1 className="text-2xl font-bold mb-4 text-green-600">Płatność zakończona sukcesem!</h1>

            <p className="text-gray-700 text-center max-w-md mb-6">
                Dziękujemy za dokonanie płatności.
                Twoje konto powinno zostać uaktualnione w ciągu kilku chwil.
                {/* Możesz podać sessionId albo cokolwiek innego, jeśli chcesz. */}
            </p>

            <button
                onClick={handleGoToDashboard}
                className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-md"
            >
                Przejdź do panelu
            </button>
        </div>
    );
}

export default CheckoutSuccess;
