import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import plTranslations from './pl/translation.json';
import enTranslations from './en/translation.json';
import ruTranslations from './ru/translation.json';
import ukTranslations from './uk/translation.json';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            pl: { translation: plTranslations },
            en: { translation: enTranslations },
            ru: { translation: ruTranslations },
            uk: { translation: ukTranslations },
        },
        fallbackLng: 'pl',
        interpolation: {
            escapeValue: false,
        }
    });

export default i18n;
