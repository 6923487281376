import React from 'react';
import { useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
    const { t } = useTranslation();

    const sections = [
        {
            title: t('privacyPolicy.section1.title'),
            description: t('privacyPolicy.section1.description'),
        },
        {
            title: t('privacyPolicy.section2.title'),
            subsections: [
                {
                    title: t('privacyPolicy.section2.sub1.title'),
                    description: t('privacyPolicy.section2.sub1.description'),
                },
                {
                    title: t('privacyPolicy.section2.sub2.title'),
                    description: t('privacyPolicy.section2.sub2.description'),
                },
                {
                    title: t('privacyPolicy.section2.sub3.title'),
                    description: t('privacyPolicy.section2.sub3.description'),
                },
                {
                    title: t('privacyPolicy.section2.sub4.title'),
                    description: t('privacyPolicy.section2.sub4.description'),
                },
            ],
        },
        {
            title: t('privacyPolicy.section3.title'),
            description: t('privacyPolicy.section3.description'),
        },
        {
            title: t('privacyPolicy.section4.title'),
            description: t('privacyPolicy.section4.description'),
        },
        {
            title: t('privacyPolicy.section5.title'),
            description: t('privacyPolicy.section5.description'),
        },
        {
            title: t('privacyPolicy.section6.title'),
            description: t('privacyPolicy.section6.description'),
        },
        {
            title: t('privacyPolicy.section7.title'),
            description: t('privacyPolicy.section7.description'),
            rights: t('privacyPolicy.section7.rights', { returnObjects: true }),
            contact: t('privacyPolicy.section7.contact')
        },
        {
            title: t('privacyPolicy.section8.title'),
            description: t('privacyPolicy.section8.description'),
        },
        {
            title: t('privacyPolicy.section9.title'),
            description: t('privacyPolicy.section9.description'),
        },
        {
            title: t('privacyPolicy.section10.title'),
            description: t('privacyPolicy.section10.description'),
        },
    ];

    return (
        <div className="min-h-screen p-8 bg-white text-gray-800">
            <div className="container mx-auto text-left rounded-lg p-6">
                <h1 className="text-4xl font-bold mb-6">{t('privacyPolicy.mainTitle')}</h1>
                <p className="mb-6 text-lg">{t('privacyPolicy.lastUpdate')}</p>

                {sections.map((section, index) => (
                    <section key={index} className="mb-8">
                        <h2 className="text-2xl font-semibold mb-4">{section.title}</h2>
                        {section.description && (
                            <p className="mb-4 whitespace-pre-line">{section.description}</p>
                        )}
                        {section.subsections &&
                            section.subsections.map((subsection, subIndex) => (
                                <div key={subIndex}>
                                    <h3 className="text-xl font-semibold mb-2">{subsection.title}</h3>
                                    <p className="mb-4 whitespace-pre-line">{subsection.description}</p>
                                </div>
                            ))}
                        {section.rights && (
                            <ul className="list-disc ml-8">
                                {section.rights.map((right, rightIndex) => (
                                    <li key={rightIndex} className="mb-2">
                                        {right}
                                    </li>
                                ))}
                            </ul>
                        )}
                        {section.contact && <p className="mb-4">{section.contact}</p>}
                    </section>
                ))}
            </div>
        </div>
    );
};

export default PrivacyPolicy;
