import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from '../../ui/modal';
import { Button } from '../../ui/button';
import { Alert, AlertDescription, AlertTitle } from '../../ui/alert';
import { useTranslation } from 'react-i18next';

const EmailVerificationModal = ({ isOpen, onClose, onResendVerification, userEmail }) => {
    const { t } = useTranslation();

    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [canResend, setCanResend] = useState(true);
    const [resendTimer, setResendTimer] = useState(0);

    const handleResendClick = async () => {
        setIsLoading(true);
        setError('');
        setMessage('');

        try {
            await onResendVerification();
            setMessage(t('emailVerification.successMessage'));
            setCanResend(false);
            setResendTimer(60); // Ustaw czas oczekiwania (60 sekund)

            const timerInterval = setInterval(() => {
                setResendTimer((prev) => {
                    if (prev <= 1) {
                        clearInterval(timerInterval);
                        setCanResend(true);
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);
        } catch (err) {
            setError(t('emailVerification.errorMessage'));
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalHeader>
                <h2 className="text-lg font-medium text-[#1B4965]">{t('emailVerification.title')}</h2>
            </ModalHeader>
            <ModalBody>
                <p className="text-gray-700">{t('emailVerification.description1')}</p>
                <p className="text-gray-700">{t('emailVerification.description2')}</p>

                <div className="mt-4 p-4 bg-gray-100 rounded-md">
                    <p className="text-sm text-gray-500">{t('emailVerification.yourEmail')}</p>
                    <p className="text-lg font-medium text-gray-800">{userEmail}</p>
                </div>

                {error && (
                    <Alert variant="destructive" className="mt-4">
                        <AlertTitle>{t('emailVerification.errorTitle')}</AlertTitle>
                        <AlertDescription>{error}</AlertDescription>
                    </Alert>
                )}

                {message && (
                    <Alert variant="success" className="mt-4">
                        <AlertTitle>{t('emailVerification.successTitle')}</AlertTitle>
                        <AlertDescription>{message}</AlertDescription>
                    </Alert>
                )}
            </ModalBody>
            <ModalFooter className="flex justify-between">
                <Button
                    onClick={handleResendClick}
                    disabled={!canResend || isLoading}
                    className="bg-[#00D1A7] text-white hover:bg-[#00B594]"
                >
                    {isLoading
                        ? t('emailVerification.sending')
                        : canResend
                            ? t('emailVerification.resendButton')
                            : t('emailVerification.resendTimer', { seconds: resendTimer })}
                </Button>
                <Button onClick={onClose} className="bg-gray-500 text-white hover:bg-gray-600">
                    {t('emailVerification.closeButton')}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default EmailVerificationModal;
