'use client';

// Link do portalu klienta
const customerPortalLink = 'https://billing.stripe.com/p/login/14kdSs2fmgCA2xG144';

// Funkcja logowania
const signIn = () => {
    // Implementacja funkcji logowania
    // Przykład:
    // auth.signInWithEmailAndPassword(email, password)
    //     .then((userCredential) => {
    //         // Zalogowany użytkownik
    //     })
    //     .catch((error) => {
    //         // Błąd logowania
    //     });
};

const ButtonCustomerPortal = ({ user }) => {
    if (user) {
        return (
            <a
                href={
                    customerPortalLink +
                    '?prefilled_email=' +
                    user.email
                }
                className="p-2 bg-[#00D1A7] text-white rounded-lg hover:bg-[#00B594] disabled:opacity-50"
            >
               Zarządzaj subskrypcją
            </a>
        );
    }

    return (
        <button onClick={signIn} className="btn">
            Zaloguj się
        </button>
    );
};

export default ButtonCustomerPortal;
