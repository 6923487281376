import React from "react";
import { useNavigate } from "react-router-dom";

function CheckoutCancel() {
    const navigate = useNavigate();

    const handleReturnToPricing = () => {
        navigate("/cennik");
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-white">
            <h1 className="text-2xl font-bold mb-4 text-red-600">
                Płatność została anulowana
            </h1>

            <p className="text-gray-700 text-center max-w-md mb-6">
                Wygląda na to, że nie dokończyłeś płatności.
                Jeśli potrzebujesz więcej czasu lub zmieniłeś zdanie, możesz wrócić na
                stronę planów i spróbować ponownie.
            </p>

            <button
                onClick={handleReturnToPricing}
                className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-md"
            >
                Wróć do planów
            </button>
        </div>
    );
}

export default CheckoutCancel;
