import {
    Accordion,
    AccordionItem,
    AccordionContent,
} from "../components/ui/accordion";
import { Helmet } from 'react-helmet';
import { useTranslation } from "react-i18next";

export default function FAQ() {
    const { t } = useTranslation();

    const faqData = [
        {
            question: t('faq.question1'),
            answer: t('faq.answer1'),
        },
        {
            question: t('faq.question2'),
            answer: t('faq.answer2'),
        },
        {
            question: t('faq.question3'),
            answer: t('faq.answer3'),
        },
        {
            question: t('faq.question4'),
            answer: t('faq.answer4'),
        },
        {
            question: t('faq.question5'),
            answer: t('faq.answer5'),
        },
    ];

    return (
        <>
            <Helmet>
                <title>{t('faq.seoTitle')}</title>
                <meta
                    name="description"
                    content={t('faq.seoDescription')}
                />
            </Helmet>

            <section className="py-24 md:py-40 bg-gray-50 min-h-screen">
                <div className="container mx-auto px-8 md:px-12 max-w-5xl">
                    <h1 className="text-4xl md:text-5xl lg:text-6xl font-extrabold text-center mb-16 text-[#1B4965]">
                        {t('faq.title')}
                    </h1>

                    <Accordion type="single" collapsible className="space-y-6">
                        {faqData.map((item, index) => (
                            <AccordionItem key={index} title={item.question} className="text-lg md:text-xl">
                                <AccordionContent className="text-lg md:text-xl">
                                    {item.answer}
                                </AccordionContent>
                            </AccordionItem>
                        ))}
                    </Accordion>
                </div>
            </section>
        </>
    );
}
