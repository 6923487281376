import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from '../../ui/modal';
import { Button } from '../../ui/button';
import { useTranslation } from 'react-i18next';

const DeleteAccountModal = ({ isOpen, onClose, onDeleteAccount }) => {
    const { t } = useTranslation();

    const [deleteError, setDeleteError] = useState('');
    const [deleteSuccess, setDeleteSuccess] = useState('');

    const handleDelete = async () => {
        setDeleteError('');
        setDeleteSuccess('');

        try {
            await onDeleteAccount();
            setDeleteSuccess(t('deleteAccount.successMessage'));
        } catch (error) {
            if (error.code === 'auth/wrong-password') {
                setDeleteError(t('deleteAccount.error.wrongPassword'));
            } else {
                setDeleteError(error.message || t('deleteAccount.error.generic'));
            }
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalHeader>
                <h2 className="text-lg font-medium text-gray-900">{t('deleteAccount.title')}</h2>
            </ModalHeader>
            <ModalBody>
                <p className="text-sm text-gray-600">
                    {t('deleteAccount.description')}
                </p>
                {deleteError && <div className="text-red-500 text-sm mt-2">{deleteError}</div>}
                {deleteSuccess && <div className="text-green-500 text-sm mt-2">{deleteSuccess}</div>}
            </ModalBody>
            <ModalFooter className="flex justify-between">
                <Button    className=" text-gray-700 hover:bg-gray-200 border-2 border-gray-300 font-medium" onClick={onClose}>
                    {t('deleteAccount.cancelButton')}
                </Button>
                <Button variant="destructive" onClick={handleDelete} className="bg-red-500 hover:bg-red-600 text-white">
                    {t('deleteAccount.confirmButton')}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default DeleteAccountModal;
