// src/components/ui/tooltip.js

import React, { useState } from 'react';

const Tooltip = ({ children, content, placement = 'top' }) => {
    const [visible, setVisible] = useState(false);

    /**
     * 1) Pozycjonowanie CAŁEGO tooltipa względem elementu-rodzica.
     *    - 'top'    => tooltip nad elementem
     *    - 'bottom' => tooltip pod elementem
     *    - 'left'   => tooltip z lewej strony
     *    - 'right'  => tooltip z prawej strony
     *
     * Zwracamy klasy Tailwind, które przesuwają tooltip.
     */
    const getPositionClasses = () => {
        switch (placement) {
            case 'top':
                // Wyświetlamy tooltip NAD elementem => box jest na dole tooltipa.
                return 'bottom-full mb-2';
            case 'bottom':
                // Wyświetlamy tooltip POD elementem => box jest na górze tooltipa.
                return 'top-full mt-2';
            case 'left':
                return 'right-full mr-2';
            case 'right':
                return 'left-full ml-2';
            default:
                return 'bottom-full mb-2'; // domyślnie 'top'
        }
    };

    /**
     * 2) Pozycjonowanie STRZAŁKI (mały kwadrat obrócony o 45°).
     *    - Dla 'top': strzałka będzie u dołu tooltipa (bo tooltip jest "u góry"),
     *      więc jej pozycja -> bottom-0 (+ translate-y-1/2).
     *    - Dla 'bottom': strzałka będzie u góry tooltipa, itd.
     */
    const getArrowClasses = () => {
        switch (placement) {
            case 'top':
                // Strzałka na dole tooltipa (tooltip jest nad elementem)
                return 'top-auto bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 rotate-45';
            case 'bottom':
                // Strzałka na górze tooltipa (tooltip jest pod elementem)
                return 'bottom-auto top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rotate-45';
            case 'left':
                // Strzałka z prawej strony tooltipa
                return 'right-0 top-1/2 transform -translate-y-1/2 translate-x-1/2 rotate-45';
            case 'right':
                // Strzałka z lewej strony tooltipa
                return 'left-0 top-1/2 transform -translate-y-1/2 -translate-x-1/2 rotate-45';
            default:
                // Domyślnie: top
                return 'top-auto bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 rotate-45';
        }
    };

    return (
        <div
            className="relative inline-flex" // 'inline-flex', żeby tooltip był w linii z dziećmi
            onMouseEnter={() => setVisible(true)}
            onMouseLeave={() => setVisible(false)}
            onFocus={() => setVisible(true)}
            onBlur={() => setVisible(false)}
            tabIndex="0"
        >
            {/* Element wywołujący tooltip (Trigger) */}
            {children}

            {/* Sam tooltip */}
            {visible && (
                <div
                    className={`
            absolute z-20 max-w-lg bg-gray-800 text-white text-sm rounded-lg p-2 shadow-lg
            transition-opacity duration-200 break-words 
            ${getPositionClasses()}
          `}
                    role="tooltip"
                >
                    {content}
                    <div
                        className={`
              absolute w-2 h-2 bg-gray-800 
              ${getArrowClasses()}
            `}
                    />
                </div>
            )}
        </div>
    );
};

export default Tooltip;
