import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../components/ui/card";
import { Star } from 'lucide-react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

export default function Testimonials() {
    const { t } = useTranslation();

    const testimonials = [
        { name: "Bartek", school: "Akademia Humanitas", quote: t("testimonials.quote1"), rating: 5 },
        { name: "Dominika", school: "Uniwersytet WSB Merito", quote: t("testimonials.quote2"), rating: 5 },
        { name: "Wiktor", school: "Uniwersytet WSB Merito", quote: t("testimonials.quote3"), rating: 5 },
    ];

    return (
        <>
            <Helmet>
                <title>{t("testimonials.meta.title")}</title>
                <meta name="description" content={t("testimonials.meta.description")} />
            </Helmet>

            <section className="py-20 md:py-32 bg-gray-50 min-h-screen">
                <div className="container max-w-6xl px-6 md:px-10 mx-auto">
                    <h1 className="text-4xl md:text-5xl lg:text-6xl font-extrabold text-center mb-8 md:mb-12 text-[#1B4965]">
                        {t("testimonials.title")}
                    </h1>
                    <p className="text-lg md:text-xl text-center mb-12 text-gray-700 max-w-3xl mx-auto">
                        {t("testimonials.subtitle")}
                    </p>

                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                        {testimonials.map((testimonial, index) => (
                            <Card key={index} className="bg-white shadow-lg hover:shadow-xl transition-shadow duration-300 rounded-xl p-6 text-center">
                                <CardHeader>
                                    <CardTitle className="text-xl font-bold text-[#1B4965]">{testimonial.name}</CardTitle>
                                    <CardDescription className="text-sm text-gray-600">{testimonial.school}</CardDescription>
                                </CardHeader>
                                <CardContent>
                                    <p className="text-gray-700 mb-4 italic">"{testimonial.quote}"</p>
                                    <div className="flex justify-center gap-1">
                                        {Array.from({ length: 5 }).map((_, i) => (
                                            <Star
                                                key={i}
                                                className={`h-5 w-5 ${i < testimonial.rating ? 'text-yellow-400' : 'text-gray-300'}`}
                                                fill={i < testimonial.rating ? 'currentColor' : 'none'}
                                            />
                                        ))}
                                    </div>
                                </CardContent>
                            </Card>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
}
