import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore'; // Import Firestore

const firebaseConfig = {
    apiKey: "AIzaSyD_gV4Yl1LBrqCD6fsjsdkfhx1MIrwT4m8",
    authDomain: "quizonator-a4513.firebaseapp.com",
    projectId: "quizonator-a4513",
    storageBucket: "quizonator-a4513.appspot.com",
    messagingSenderId: "701053755458",
    appId: "1:701053755458:web:fc67a480457d3054c58ddb"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app); // Inicjalizacja Firestore

export { auth, firestore };
