import React, { useState, useEffect } from "react";
import { auth } from "../firebase";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "../components/ui/button";
import { Loader2 } from "lucide-react";

export default function Payment() {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    // Odczytaj parametry z URL, np. ?plan=monthly
    const queryParams = new URLSearchParams(location.search);
    const planType = queryParams.get("plan"); // "monthly", "semestral" lub inny
    
    useEffect(() => {
        const initiatePayment = async () => {
            try {
                setLoading(true);
                const user = auth.currentUser;
                if (!user) {
                    navigate("/login");
                    return;
                }
                // Pobieramy token Firebase do autoryzacji w backendzie
                const token = await user.getIdToken();

                // Wywołujemy endpoint w chmurze
                const res = await fetch(
                    "https://us-central1-quizonator-a4513.cloudfunctions.net/api/create-checkout-session",
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`, // jeśli weryfikujesz JWT w backendzie
                        },
                        body: JSON.stringify({
                            userId: user.uid,
                            email: user.email,
                            planType: planType, // 'semestral'
                        }),
                    }
                );

                const data = await res.json();

                if (data.url) {
                    window.location.href = data.url
                } else {
                    console.error("Błąd w odpowiedzi backendu", data);
                    setLoading(false); // Zapewnia, że loader zniknie po błędzie
                }
            } catch (error) {
                console.error("Błąd create-payu-order:", error);
                setLoading(false); // Zapewnia, że loader zniknie po błędzie
            } finally {
            }
        };

        initiatePayment();
    }, [planType, navigate, location]);

    return (
        <div className="flex flex-col items-center justify-center min-h-screen py-10">
            {loading ? (
                <div className="flex flex-col items-center space-y-4">
                    <Loader2 className="animate-spin w-12 h-12 text-[#00D1A7]" />
                    <p className="text-lg text-gray-700 font-semibold">
                        Trwa przekierowanie do płatności...
                    </p>
                    <p className="text-sm text-gray-500 max-w-md text-center">
                        Prosimy o chwilę cierpliwości, zaraz przeniesiemy Cię do bezpiecznego systemu płatności Stripe.
                    </p>
                </div>
            ) : (
                <div className="flex flex-col items-center space-y-4">
                    <p className="text-lg font-semibold text-red-600">
                        Jeśli widzisz tę stronę, to znaczy, że coś poszło nie tak.
                    </p>
                    <Button
                        onClick={() => navigate("/cennik")}
                        className="text-sm px-4 py-2 bg-[#00D1A7] text-white hover:bg-[#00B594] transition-colors"
                    >
                        Wróć do Cennika
                    </Button>
                </div>
            )}
        </div>
    );
}
