import React from 'react';
import { Card, CardContent, CardHeader, CardDescription, CardTitle } from '../ui/card';
import { Progress } from '../ui/progress';
import BackButton from './BackButton';
import { useTranslation } from 'react-i18next';

const Statistics = ({ userData, onBack }) => {
    const { t } = useTranslation();

    return (
        <Card className="max-w-4xl mx-auto p-6 shadow-lg rounded-lg bg-white">
            <BackButton onClick={onBack} />

            <CardHeader>
                <CardTitle>{t("statistics.title")}</CardTitle>
                <CardDescription>{t("statistics.description")}</CardDescription>
            </CardHeader>
            <CardContent>
                <div className="space-y-6">
                    <div>
                        <div className="flex justify-between mb-1">
                            <span className="text-sm font-medium">{t("statistics.requests")}</span>
                            <span className="text-sm font-medium">
                                {userData?.requestCount} / {userData?.requestLimit}
                            </span>
                        </div>
                        <Progress
                            value={
                                (userData?.requestCount / (userData?.requestLimit || 1)) * 100
                            }
                        />
                    </div>
                    <div>
                        <h3 className="text-lg font-medium">{t("statistics.completedQuizzes")}</h3>
                        <p className="text-3xl font-bold">{userData?.quizCount || 0}</p>
                    </div>
                </div>
            </CardContent>
        </Card>
    );
};

export default Statistics;
