import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

export default function AboutUs() {
    const { t } = useTranslation();

    const teamMembers = [
        { name: "Dominik", role: t("about.team.ceo") },
        { name: "Konrad", role: t("about.team.developer") },
        { name: "Dima", role: t("about.team.marketing") },
        { name: "Bartosz", role: t("about.team.tester") },
        { name: "Wiktor", role: t("about.team.tester") },
        { name: "Mateusz", role: t("about.team.designer") },
    ];

    return (
        <>
            <Helmet>
                <title>{t("about.meta.title")}</title>
                <meta name="description" content={t("about.meta.description")} />
            </Helmet>

            <section className="py-20 md:py-32 bg-gray-50">
                <div className="container max-w-6xl px-6 md:px-10 mx-auto">
                    <h1 className="text-4xl md:text-5xl font-extrabold text-center mb-16 text-[#1B4965]">
                        {t("about.header")}
                    </h1>

                    <div className="mb-20">
                        <h2 className="text-3xl font-bold mb-6 text-[#1B4965] text-center">
                            {t("about.mission.title")}
                        </h2>
                        <p className="text-lg text-gray-700 leading-relaxed text-center mx-auto max-w-3xl">
                            {t("about.mission.description")}
                        </p>
                    </div>

                    <div>
                        <h2 className="text-3xl md:text-4xl font-bold text-center mb-12 text-[#1B4965]">
                            {t("about.team.title")}
                        </h2>
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                            {teamMembers.map((member, index) => (
                                <Card
                                    key={index}
                                    className="bg-white shadow-lg hover:shadow-xl transition-shadow duration-300 rounded-lg p-6 text-center"
                                >
                                    <CardHeader>
                                        <CardTitle className="text-xl font-semibold text-[#1B4965]">
                                            {member.name}
                                        </CardTitle>
                                    </CardHeader>
                                    <CardContent>
                                        <p className="text-lg font-medium text-gray-600">
                                            {member.role}
                                        </p>
                                    </CardContent>
                                </Card>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
