import { useState, useEffect } from 'react';
import { Button } from "../components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import { Download, Camera, Sparkles, Eye } from 'lucide-react';
import thumbnail from "../assets/thumbnail.png"
import { logEvent } from '../analytics';
import { Helmet } from 'react-helmet';
import { useTranslation } from "react-i18next";

export default function HowItWorks() {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState('auto-detect'); // Zmieniono domyślnie aktywny tryb na automatyczne wykrywanie
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    // Sprawdzenie rozmiaru ekranu
    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 768);
        };

        handleResize(); // Wywołaj na początku
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    useEffect(() => {
        logEvent('PageView', 'HowItWorks', 'Odwiedzono stronę "Jak to działa"');
    }, []);
    return (
        <>
            <Helmet>
                <title>{t("howItWorks.meta.title")}</title>
                <meta name="description" content={t("howItWorks.meta.description")} />
                <meta name="keywords" content={t("howItWorks.meta.keywords")} />
                <meta property="og:title" content={t("howItWorks.meta.ogTitle")} />
                <meta property="og:description" content={t("howItWorks.meta.ogDescription")} />
                <meta property="og:image" content="https://quizonator.com/og-image.png" />
                <meta property="og:url" content="https://quizonator.com/jak-to-dziala" />
                <meta property="og:type" content="website" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={t("howItWorks.meta.twitterTitle")} />
                <meta name="twitter:description" content={t("howItWorks.meta.twitterDescription")} />
                <meta name="twitter:image" content="https://quizonator.com/twitter-image.png" />
            </Helmet>


            <section className="py-20 md:py-32 bg-gray-50">
                <div className="container mx-auto px-6 md:px-10">
                    {/* Nagłówek sekcji */}
                    <h1 className="text-4xl md:text-5xl lg:text-6xl font-extrabold text-center mb-8 md:mb-12 text-[#1B4965]">
                        {t("howItWorks.header.title")}
                    </h1>
                    <p className="text-lg md:text-xl text-center mb-12 text-gray-700 max-w-2xl mx-auto">
                        {t("howItWorks.header.description")}
                    </p>

                    {/* Selektor trybu */}
                    <div className="flex flex-wrap justify-center mb-12 gap-4">
                        {[
                            { id: "auto-detect", label: t("howItWorks.modes.autoDetect") },
                            { id: "screenshot", label: t("howItWorks.modes.screenshot") },
                        ].map(({ id, label }) => (
                            <Button
                                key={id}
                                onClick={() => setActiveTab(id)}
                                className={`px-4 md:px-6 py-2 rounded-full font-semibold ${activeTab === id
                                    ? "bg-[#00D1A7] text-white shadow-md"
                                    : "bg-white text-[#1B4965] border border-[#1B4965] hover:bg-[#E6ECF8]"
                                    } transition-all text-sm md:text-base`}
                            >
                                {label}
                            </Button>
                        ))}
                    </div>


                    {/* Treść dla wybranego trybu */}
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 mb-16">
                        {(activeTab === "auto-detect"
                            ? t("howItWorks.autoDetectSteps", { returnObjects: true })
                            : t("howItWorks.screenshotSteps", { returnObjects: true })
                        ).map((step, index) => (
                            <Card
                                key={index}
                                className="text-center bg-white shadow-lg hover:shadow-2xl transition-all duration-300 rounded-lg p-6"
                            >
                                <CardHeader>
                                    <div className="flex justify-center mb-4">{step.icon === "download" && <Download className="h-12 w-12 mb-4 text-[#00D1A7]" />}{step.icon === "eye" && <Eye className="h-12 w-12 mb-4 text-[#00D1A7]" />}{step.icon === "camera" && <Camera className="h-12 w-12 mb-4 text-[#00D1A7]" />}{step.icon === "sparkles" && <Sparkles className="h-12 w-12 mb-4 text-[#00D1A7]" />}</div>
                                    <CardTitle className="text-xl md:text-2xl font-bold text-[#1B4965]">
                                        {step.title}
                                    </CardTitle>
                                </CardHeader>
                                <CardContent>
                                    <p className="text-gray-600">{step.description}</p>
                                </CardContent>
                            </Card>
                        ))}
                    </div>

                    {/* Przycisk CTA */}
                    <div className="text-center mb-20">
                        <Button
                            size="lg"
                            className="bg-gradient-to-r from-[#00D1A7] to-[#00B594] text-white font-semibold px-6 py-3 rounded-full shadow-md hover:shadow-lg transition-transform transform hover:scale-105"
                            onClick={() => {
                                window.open(
                                    "https://chrome.google.com/webstore/detail/quizonator/dhphdklknbffcgahfpiipdmafoncnndi",
                                    "_blank"
                                );
                                logEvent("HowItWorks", "Click", t("howItWorks.ctaLog"));
                            }}
                        >
                            {t("howItWorks.cta")}
                        </Button>
                    </div>


                    {/* Sekcja demo */}
                    <div className="bg-[#F8FAFC] rounded-xl p-8 shadow-lg">
                        <h2 className="text-2xl md:text-3xl font-extrabold text-center mb-6 text-[#1B4965]">
                            Quizonator w akcji
                        </h2>
                        <div
                            className="relative pb-[56.25%] h-0 overflow-hidden rounded-lg shadow-md cursor-pointer"
                            onClick={() => {
                                if (isSmallScreen) {
                                    setIsModalOpen(true);
                                    logEvent('HowItWorks', 'Click', 'Otwórz wideo demo na małym ekranie');
                                }
                            }}
                        >
                            {/* Na dużych ekranach iframe jest zawsze widoczny */}
                            {!isSmallScreen && (
                                <iframe
                                    id="quizonator-video"
                                    className="absolute top-0 left-0 w-full h-full"
                                    src="https://www.youtube.com/embed/6BTdc-W9Co8"
                                    title="Demo Quizonator"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                            )}

                            {/* Obraz zastępczy dla małych ekranów */}
                            {isSmallScreen && (
                                <img src={thumbnail} alt="Thumbnail" className="absolute top-0 left-0 w-full h-full object-cover" />
                            )}
                        </div>

                        {/* Modal dla małych ekranów */}
                        {isSmallScreen && isModalOpen && (
                            <div className="fixed inset-0 bg-black bg-opacity-75 z-50 flex items-center justify-center">
                                <div className="relative bg-white rounded-lg shadow-xl w-[90%] md:w-[80%] overflow-hidden">
                                    <button
                                        className="absolute top-4 right-4 text-white bg-black bg-opacity-50 p-2 rounded-full z-10"
                                        onClick={() => setIsModalOpen(false)}
                                    >
                                        ✕
                                    </button>
                                    <div className="aspect-video">
                                        <iframe
                                            className="w-full h-full"
                                            src="https://www.youtube.com/embed/6BTdc-W9Co8?autoplay=1"
                                            title="Demo Quizonator"
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                        ></iframe>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>


                </div>
            </section>
        </>
    );
}
