import React from 'react';
import { useTranslation } from "react-i18next";

const TermsOfService = () => {
    const { t } = useTranslation();

    const sections = [
        {
            title: t('termsOfService.section1.title'),
            content: t('termsOfService.section1.content'),
        },
        {
            title: t('termsOfService.section2.title'),
            content: t('termsOfService.section2.content'),
        },
        {
            title: t('termsOfService.section3.title'),
            content: t('termsOfService.section3.content'),
        },
        {
            title: t('termsOfService.section4.title'),
            content: t('termsOfService.section4.content'),
        },
        {
            title: t('termsOfService.section5.title'),
            content: t('termsOfService.section5.content'),
        },
        {
            title: t('termsOfService.section6.title'),
            content: t('termsOfService.section6.content'),
        },
        {
            title: t('termsOfService.section7.title'),
            content: t('termsOfService.section7.content'),
        },
        {
            title: t('termsOfService.section8.title'),
            content: t('termsOfService.section8.content'),
        },
        {
            title: t('termsOfService.section9.title'),
            content: t('termsOfService.section9.content'),
        },
        {
            title: t('termsOfService.section10.title'),
            content: t('termsOfService.section10.content'),
        },
    ];

    return (
        <div className="min-h-screen p-8 bg-white text-gray-800">
            <div className="container mx-auto text-left rounded-lg p-6">
                <h1 className="text-4xl font-bold mb-6">{t('termsOfService.mainTitle')}</h1>
                <p className="mb-6 text-lg">{t('termsOfService.lastUpdate')}</p>

                {sections.map((section, index) => (
                    <section key={index} className="mb-8">
                        <h2 className="text-2xl font-semibold mb-4">{section.title}</h2>
                        <p className="whitespace-pre-line">{section.content}</p>
                    </section>
                ))}
            </div>
        </div>
    );
};

export default TermsOfService;
