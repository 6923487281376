import { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { Helmet } from "react-helmet"
import { useTranslation } from "react-i18next"
import { logEvent } from "../analytics"
import { auth } from "../firebase"

import { Button } from "../components/ui/button"
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "../components/ui/card"
import { CheckCircle, Timer, Sparkles } from "lucide-react"
import { Badge } from "../components/ui/badge"
import Tooltip from "../components/ui/tooltip.js"
import { Modal } from "../components/ui/modal"



function PricingCard({ plan, isSemestral, onCtaClick, user }) {
    const { t } = useTranslation();

    const hasActivePlan =
        user?.plan === (isSemestral ? "semestral" : "monthly") &&
        user?.subscriptionEndDate &&
        new Date() < new Date(user.subscriptionEndDate);

    return (
        <Card className="relative overflow-visible bg-white rounded-2xl shadow-lg hover:shadow-xl transition-all">
            {/* Badge */}
            {isSemestral && plan.savings && (
                <div className="absolute top-6 right-6">
                    <Tooltip content={plan.savings.details} placement="top">
                        <Badge className="bg-[#FF5722] text-white px-3 py-1 text-sm font-medium flex items-center gap-1 cursor-pointer">
                            <Sparkles className="w-4 h-4" />
                            {plan.savings.badgeText}
                        </Badge>
                    </Tooltip>
                </div>
            )}

            {/* Header */}
            <CardHeader className="text-center pt-12">
                <CardTitle className="text-3xl font-bold text-[#1B4965] mb-2">{plan.planName}</CardTitle>
                <CardDescription className="text-lg text-gray-600">{t("pricing.card.description")}</CardDescription>
            </CardHeader>

            {/* Content */}
            <CardContent className="space-y-8">
                <div className="text-center">
                    <div className="flex flex-col items-center gap-2 mb-2">
                        <div className="flex items-center gap-4">
                            <span className="text-5xl font-bold text-gray-500">{plan.price}</span>
                        </div>
                        <div className="flex items-center justify-center gap-2 text-gray-600">
                            <Timer className="w-5 h-5" />
                            <span className="text-sm sm:text-lg">{plan.validity}</span>
                        </div>
                    </div>
                </div>

                <div className="space-y-4">
                    {plan.features.map((feature, index) => (
                        <div key={index} className="flex items-center gap-3">
                            <CheckCircle className="w-5 h-5 text-[#00D1A7] flex-shrink-0" />
                            <span className="text-gray-700 text-sm sm:text-lg">{feature}</span>
                        </div>
                    ))}
                </div>
            </CardContent>

            {/* Disclaimer */}
            <CardFooter className="text-center text-sm text-gray-600">
                {t("pricing.trial.disclaimer")}
            </CardFooter>

            {/* Footer */}
            <CardFooter className="flex flex-col gap-4 pb-8">
                <Button
                    size="lg"
                    className={`w-full py-3 rounded-lg shadow font-semibold ${hasActivePlan
                        ? "bg-gray-300 text-gray-700 cursor-not-allowed"
                        : "bg-gradient-to-r from-[#00D1A7] to-[#00B594] text-white hover:from-[#00B594] hover:to-[#008F78]"
                        }`}
                    disabled={hasActivePlan}
                    onClick={!hasActivePlan ? onCtaClick : undefined}
                >
                    {user
                        ? hasActivePlan
                            ? t("pricing.card.btnActive") // Tekst, jeśli plan jest aktywny
                            : isSemestral
                                ? t("pricing.trial.btnBuySemester") // Kup plan semestralny
                                : t("pricing.trial.btnBuyMonthly") // Kup plan miesięczny
                        : t("pricing.trial.btnRegisterWith20Free")} {/* Rozpocznij okres próbny */}
                </Button>
            </CardFooter>
        </Card>
    );
}




export default function Pricing() {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const [user, setUser] = useState(null)
    const [showModal, setShowModal] = useState(false)
    useEffect(() => {
        logEvent("PageView", "Pricing", t("pricing.pageViewLog"));

        const fetchUserData = async () => {
            try {
                const currentUser = auth.currentUser;

                if (!currentUser) {
                    setUser(null);
                    return;
                }

                const token = await currentUser.getIdToken(); // Pobierz token Firebase
                const response = await fetch("https://us-central1-quizonator-a4513.cloudfunctions.net/api/getUserDataWithQuizSummaries", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`, // Przekaz token w nagłówku
                    },
                    body: JSON.stringify({ email: currentUser.email }), // Przekaż e-mail w ciele zapytania
                });

                if (!response.ok) {
                    throw new Error("Failed to fetch user data");
                }

                const userData = await response.json();

                setUser(userData.userData);
            } catch (error) {
                console.error("Error fetching user data:", error);
                setUser(null);
            }
        };

        fetchUserData();
    }, [t]);


    useEffect(() => {
        logEvent("PageView", "Pricing", t("pricing.pageViewLog"))

        const unsubscribe = auth.onAuthStateChanged((currentUser) => {
            setUser(currentUser)
        })
        return () => unsubscribe()
    }, [t])


    const monthlyData = {
        planName: t("pricing.monthly.planName"),
        price: t("pricing.monthly.price"),
        validity: t("pricing.monthly.validity"),
        features: t("pricing.monthly.features", { returnObjects: true }),
    }

    const semestralData = {
        planName: t("pricing.semestral.planName"),
        price: t("pricing.semestral.price"),
        validity: t("pricing.semestral.validity"),
        features: t("pricing.semestral.features", { returnObjects: true }),
        savings: t("pricing.semestral.savings", { returnObjects: true }),
    }

    const handleCtaClick = (planType) => {
        if (!user) {
            navigate("/register");
        } else {
            navigate(`/payment?plan=${planType}`);
            logEvent("Button", "Click", t("pricing.card.ctaLog"));
        }
    };



    return (
        <>
            <Helmet>
                <title>{t("pricing.meta.title")}</title>
                <meta name="description" content={t("pricing.meta.description")} />
            </Helmet>

            <div className="relative bg-gray-50">
                <main className="container px-4 sm:px-6 lg:px-8 py-16 md:py-20 lg:py-14 mx-auto">
                    {/* Header Section */}
                    <div className="text-center max-w-3xl mx-auto mb-12">
                        <h1 className="text-4xl md:text-5xl lg:text-6xl font-extrabold text-[#1B4965] mb-6">
                            {t("pricing.header.title")}
                        </h1>
                        <p className="text-lg md:text-xl text-gray-700 mb-6">
                            {t("pricing.header.description")}
                            <br />
                            {/* <span className="font-semibold text-[#1B4965]">{t("pricing.trial.banner")}</span> */}
                        </p>
                        <div className="inline-flex items-center gap-2 bg-[#00D1A7]/10 text-[#1B4965] px-4 py-2 rounded-full">
                            <span className="font-medium">{t("pricing.trial.saveBanner")}</span>
                        </div>
                    </div>

                    {/* Pricing Cards */}
                    <div className="grid md:grid-cols-2 gap-8 max-w-5xl mx-auto">
                        {[monthlyData, semestralData].map((plan, index) => (
                            <PricingCard
                                key={index}
                                plan={plan}
                                isSemestral={index === 1}
                                onCtaClick={() => handleCtaClick(index === 0 ? "monthly" : "semestral")}
                                user={user}
                            />
                        ))}
                    </div>

                    {/* FAQ Section */}
                    <div className="mt-20 text-center">
                        <h2 className="text-2xl font-bold text-[#1B4965] mb-4">{t("pricing.faq.title")}</h2>
                        <p className="text-gray-600 mb-6">{t("pricing.faq.description")}</p>
                        <Link to="/faq">
                            <Button
                                variant="outline"
                                className="text-[#1B4965] border-[#1B4965] hover:bg-[#1B4965] hover:text-white transition-all"
                            >
                                {t("pricing.faq.button")}
                            </Button>
                        </Link>
                    </div>
                </main>
            </div>

            {/* Modal for logged-in user */}
            <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
                <div className="relative bg-white rounded-lg shadow-lg p-6 max-w-md mx-auto text-center">
                    <button
                        onClick={() => setShowModal(false)}
                        className="absolute top-3 right-3 text-gray-400 hover:text-gray-600"
                        aria-label="Close"
                    >
                        ✖
                    </button>
                    <h2 className="text-2xl font-extrabold text-[#1B4965] mb-4">Darmowy dostęp do 24 stycznia!</h2>
                    <p className="text-gray-600 text-lg">
                        Aktualnie obowiązuje darmowy dostęp do 24 stycznia. Po tym terminie obowiązywać będą standardowe ceny.
                    </p>
                    <div className="mt-6">
                        <button
                            onClick={() => setShowModal(false)}
                            className="px-6 py-2 bg-[#00D1A7] text-white text-lg font-semibold rounded-lg hover:bg-[#00B594] transition-all"
                        >
                            Rozumiem
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    )
}

