import { motion } from "framer-motion";
import { X } from "lucide-react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export function PromotionPopup({ showPopup, handleClose }) {
    const { t } = useTranslation();

    if (!showPopup) return null;

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 will-change-transform"
            onClick={(e) => {
                if (e.target === e.currentTarget) {
                    handleClose();
                }
            }}
            role="dialog"
            aria-modal="true"
            aria-labelledby="promotion-title"
        >
            <motion.div
                initial={{ scale: 0.95, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.95, opacity: 0 }}
                transition={{ duration: 0.2 }}
                className="bg-gradient-to-br from-white to-gray-100 p-8 rounded-2xl shadow-2xl max-w-md w-full mx-4 text-center space-y-6 relative overflow-hidden"
            >
                <button
                    className="absolute top-3 right-3 text-gray-800 hover:text-black focus:text-black p-2 transition-all"
                    onClick={handleClose}
                    aria-label={t("promotion.close")}
                >
                    <X className="w-8 h-8" />
                </button>
                <h2
                    id="promotion-title"
                    className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-[#00D1A7] to-[#00B594]"
                >
                    {t("promotion.title")}
                </h2>
                <p className="text-xl text-gray-700 font-medium">
                    Promocja została przedłużona do 24 stycznia 2025.
                </p>
                <div className="relative">
                    <div
                        className="absolute inset-0 bg-gradient-to-r from-[#00D1A7] to-[#00B594] transform skew-y-3 rounded-lg"
                        aria-hidden="true"
                    />
                    <Link to="/cennik">
                        <button
                            className="relative bg-white text-[#00D1A7] hover:bg-gray-100 font-bold text-lg py-6 px-8 rounded-lg transition-transform transform hover:scale-105"
                            onClick={handleClose}
                        >
                            {t("promotion.button")}
                        </button>
                    </Link>
                </div>
                <p className="text-sm text-gray-500 mt-4">
                    {t("promotion.footer")}
                </p>
            </motion.div>
        </motion.div>
    );
}
