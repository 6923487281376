import React from 'react';
import { useTranslation } from "react-i18next";

const CookiePolicy = () => {
    const { t } = useTranslation();

    const sections = [
        {
            title: t('cookiePolicy.section1.title'),
            content: t('cookiePolicy.section1.content'),
        },
        {
            title: t('cookiePolicy.section2.title'),
            subsections: [
                {
                    title: t('cookiePolicy.section2.sub1.title'),
                    content: t('cookiePolicy.section2.sub1.content'),
                },
                {
                    title: t('cookiePolicy.section2.sub2.title'),
                    content: t('cookiePolicy.section2.sub2.content'),
                },
                {
                    title: t('cookiePolicy.section2.sub3.title'),
                    content: t('cookiePolicy.section2.sub3.content'),
                },
            ],
        },
        {
            title: t('cookiePolicy.section3.title'),
            content: t('cookiePolicy.section3.content'),
        },
        {
            title: t('cookiePolicy.section4.title'),
            content: t('cookiePolicy.section4.content'),
        },
        {
            title: t('cookiePolicy.section5.title'),
            content: t('cookiePolicy.section5.content'),
        },
        {
            title: t('cookiePolicy.section6.title'),
            content: t('cookiePolicy.section6.content'),
        },
        {
            title: t('cookiePolicy.section7.title'),
            content: t('cookiePolicy.section7.content'),
        },
        {
            title: t('cookiePolicy.section8.title'),
            content: t('cookiePolicy.section8.content'),
        },
    ];

    return (
        <div className="min-h-screen p-8 bg-white text-gray-800">
            <div className="container mx-auto text-left rounded-lg p-6">
                <h1 className="text-4xl font-bold mb-6">{t('cookiePolicy.mainTitle')}</h1>
                <p className="mb-6 text-lg">{t('cookiePolicy.lastUpdate')}</p>

                {sections.map((section, index) => (
                    <section key={index} className="mb-8">
                        <h2 className="text-2xl font-semibold mb-4">{section.title}</h2>
                        {section.content && (
                            <p className="mb-4 whitespace-pre-line">{section.content}</p>
                        )}
                        {section.subsections &&
                            section.subsections.map((subsection, subIndex) => (
                                <div key={subIndex} className="mb-4">
                                    <h3 className="text-xl font-semibold mb-2">{subsection.title}</h3>
                                    <p className="whitespace-pre-line">{subsection.content}</p>
                                </div>
                            ))}
                    </section>
                ))}
            </div>
        </div>
    );
};

export default CookiePolicy;
