import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardDescription, CardTitle } from '../../ui/card';
import { Button } from '../../ui/button';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../ui/table";
import { ArrowLeft, HelpCircle } from 'lucide-react';
import { Badge } from "../../ui/badge";
import { useTranslation } from 'react-i18next';
import ButtonCustomerPortal from './CustomerPortal'
function parseDate(date) {
    // Jeśli data jest obiektem Timestamp (Firestore)
    if (typeof date === "object" && date._seconds) {
        return new Date(date._seconds * 1000); // Konwersja z sekund na milisekundy
    }
    // Jeśli data jest stringiem
    if (typeof date === "string") {
        return new Date(date); // Bezpośrednia konwersja z ciągu znaków
    }
    // Jeśli format jest nieobsługiwany, zwróć invalid date
    return new Date(NaN);
}

const Payments = ({ userData, transactions, onBack }) => {
    const { t } = useTranslation();
    return (
        <div className="space-y-6">
            {/* Powrót i pomoc */}
            <div className="flex items-center justify-between">
                <Button variant="ghost" onClick={onBack} className="flex items-center gap-2">
                    <ArrowLeft className="h-4 w-4" />
                    {t('payments.backButton')}
                </Button>
                <Link to="/kontakt">
                    <Button variant="outline" className="flex items-center gap-2">
                        <HelpCircle className="h-4 w-4" />
                        {t('payments.helpButton')}
                    </Button>
                </Link>
            </div>
            {/* Nagłówek */}
            <div>
                <h2 className="text-3xl font-bold tracking-tight">{t('payments.title')}</h2>
                <p className="text-muted-foreground">
                    {t('payments.description')}
                </p>
            </div>
            {/* Status subskrypcji */}
            <Card className='border border-gray-300'>
                <CardHeader>
                    <CardTitle className='text-2xl font-semibold'>{t('payments.subscription.title')}</CardTitle>
                    <CardDescription>{t('payments.subscription.description')}</CardDescription>
                </CardHeader>
                <CardContent className="flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-4 sm:space-y-0">
                    <div>
                        <p className="text-lg font-semibold">
                            {userData?.subscriptionEndDate
                                ? new Date() < parseDate(userData.subscriptionEndDate)
                                    ? t('payments.subscription.active')
                                    : t('payments.subscription.inactive')
                                : t('payments.subscription.inactive')}
                        </p>
                        <p className="text-xs sm:text-sm text-muted-foreground">
                            {userData?.subscriptionEndDate
                                ? `${t('payments.subscription.validUntil')} ${parseDate(userData.subscriptionEndDate).toLocaleDateString()}`
                                : t('payments.subscription.unknownEndDate')}
                        </p>

                    </div>
                    <ButtonCustomerPortal user={userData} />

                </CardContent>
            </Card>

            {/* Historia transakcji */}
            <Card className='border border-gray-300'>
                <CardHeader>
                    <CardTitle>{t('payments.history.title')}</CardTitle>
                    <CardDescription>{t('payments.history.description')}</CardDescription>
                </CardHeader>
                <CardContent>
                    {transactions.length > 0 ? (
                        <>
                            {/* Tabela dla dużych ekranów */}
                            <div className="hidden sm:block overflow-x-auto">
                                <Table>
                                    <TableHeader>
                                        <TableRow>
                                            <TableHead>{t('payments.history.date')}</TableHead>
                                            <TableHead>{t('payments.history.descriptionColumn')}</TableHead>
                                            <TableHead>{t('payments.history.amount')}</TableHead>
                                            <TableHead>{t('payments.history.status')}</TableHead>
                                        </TableRow>
                                    </TableHeader>
                                    <TableBody>
                                        {transactions.map((transaction) => (
                                            <TableRow key={transaction.id}>
                                                <TableCell>{transaction.date}</TableCell>
                                                <TableCell>{transaction.description}</TableCell>
                                                <TableCell>{transaction.amount}</TableCell>
                                                <TableCell>
                                                    <Badge color={transaction.status === "Sukces" ? "green" : "red"}>
                                                        {transaction.status}
                                                    </Badge>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </div>

                            {/* Widok dla małych ekranów */}
                            <div className="block sm:hidden space-y-4">
                                {transactions.map((transaction) => (
                                    <div key={transaction.id} className="p-4 border rounded-lg bg-gray-50 shadow-sm flex flex-col space-y-2">
                                        <div className="flex justify-between items-center">
                                            <span className="font-medium text-sm">{transaction.date}</span>
                                            <Badge color={transaction.status === "Sukces" ? "green" : "red"}>
                                                {transaction.status}
                                            </Badge>
                                        </div>
                                        <p className="text-xs text-gray-600">{transaction.description}</p>
                                        <p className="text-sm font-bold">{transaction.amount}</p>
                                    </div>
                                ))}
                            </div>
                        </>
                    ) : (
                        <p className="text-center py-4 text-muted-foreground">{t('payments.history.noTransactions')}</p>
                    )}
                </CardContent>
            </Card>
        </div>
    );
};

export default Payments;