import React from 'react';
import { ArrowLeft } from 'lucide-react';
import { Button } from '../ui/button';
import { useTranslation } from 'react-i18next';

const BackButton = ({ onClick }) => {
    const { t } = useTranslation();

    return (
        <Button variant="ghost" onClick={onClick} className="mb-4">
            <ArrowLeft className="mr-2 h-4 w-4" /> {t("backButton.text")}
        </Button>
    );
};

export default BackButton;
