import { Button } from "../components/ui/button";
import { useNavigate } from "react-router-dom";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "../components/ui/card";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

export default function VerifyEmailNotification() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>{t("verifyEmailNotification.meta.title")}</title>
                <meta name="description" content={t("verifyEmailNotification.meta.description")} />
            </Helmet>
            <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-r from-gray-50 to-gray-100 px-4 sm:px-6 lg:px-8">
                <Card className="w-full max-w-md bg-white shadow-lg rounded-lg">
                    <CardHeader className="text-center space-y-1">
                        <CardTitle className="text-3xl font-semibold text-[#1B4965]">{t("verifyEmailNotification.title")}</CardTitle>
                    </CardHeader>
                    <CardContent className="p-6 space-y-6">
                        <CardDescription className="text-gray-600">
                            {t("verifyEmailNotification.description")}
                        </CardDescription>
                    </CardContent>
                    <CardFooter className="text-center text-sm text-gray-600">
                        <Button onClick={() => navigate('/login')} className="w-full py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-800">
                            {t("verifyEmailNotification.button")}
                        </Button>
                    </CardFooter>
                </Card>
            </div>
        </>
    );
}
