import React, { useState } from "react";
import { Button } from "../components/ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "../components/ui/card";
import { Input } from "../components/ui/input";
import { Textarea } from "../components/ui/textarea";
import { Mail } from 'lucide-react';
import emailjs from "@emailjs/browser";
import { Helmet } from 'react-helmet';
import { useTranslation } from "react-i18next";

export default function Contact() {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({ name: "", email: "", subject: "", message: "" });
  const [status, setStatus] = useState({ success: null, error: null });
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus({ success: null, error: null });
    setIsLoading(true);

    try {
      const response = await emailjs.send(
        "service_jilb45r",
        "template_dlnitpa",
        {
          from_name: formData.name,
          reply_to: formData.email,
          subject: formData.subject,
          message: formData.message,
        },
        "fET1LcHI6nMtO6HLS"
      );

      if (response.status === 200) {
        setStatus({ success: t("contact.successMessage"), error: null });
        setFormData({ name: "", email: "", subject: "", message: "" });
      }
    } catch {
      setStatus({ success: null, error: t("contact.errorMessage") });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>{t("contact.meta.title")}</title>
        <meta name="description" content={t("contact.meta.description")} />
      </Helmet>

      <section className="py-12 md:py-20 lg:py-32 bg-gray-50 min-h-screen">
        <div className="container max-w-6xl px-4 md:px-6 lg:px-8 mx-auto">
          <h1 className="text-3xl md:text-5xl lg:text-6xl font-bold text-center mb-12 text-[#1B4965]">
            {t("contact.header")}
          </h1>

          <div className="max-w-2xl mx-auto">
            <Card className="shadow-lg p-6 rounded-lg bg-white">
              <CardHeader>
                <CardTitle className="text-xl sm:text-2xl font-semibold text-[#1B4965]">
                  {t("contact.title")}
                </CardTitle>
                <CardDescription className="text-sm sm:text-base text-gray-600 mt-4">
                  {t("contact.description")}
                </CardDescription>
              </CardHeader>
              <div className="flex items-center space-x-4 p-3 border border-gray-300 rounded-lg my-6">
                <Mail className="h-8 w-8 text-[#00D1A7]" />
                <div>
                  <p className="text-gray-700 font-semibold">support@quizonator.com</p>
                </div>
              </div>
              <CardContent>
                <form className="space-y-4" onSubmit={handleSubmit}>
                  <Input
                    name="name"
                    placeholder={t("contact.form.name")}
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                  <Input
                    name="email"
                    placeholder={t("contact.form.email")}
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                  <Input
                    name="subject"
                    placeholder={t("contact.form.subject")}
                    value={formData.subject}
                    onChange={handleChange}
                    required
                  />
                  <Textarea
                    name="message"
                    placeholder={t("contact.form.message")}
                    rows={5}
                    value={formData.message}
                    onChange={handleChange}
                    required
                  />
                  <Button
                    type="submit"
                    className="w-full py-2 bg-[#00D1A7] text-white rounded-lg hover:bg-[#00B594] disabled:opacity-50"
                    disabled={isLoading}
                  >
                    {isLoading ? t("contact.loading") : t("contact.form.submit")}
                  </Button>
                </form>
              </CardContent>
              <CardFooter>
                {status.success && <p className="text-green-600 text-center font-medium">{status.success}</p>}
                {status.error && <p className="text-red-600 text-center font-medium">{status.error}</p>}
              </CardFooter>
            </Card>
          </div>
        </div>
      </section>
    </>
  );
}
